import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material/";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";

import newAxios from "../../../../axios/axios";
import Navigation from "./navigation";

import HU from "../../../../languages/HU/hu";
import { UserSupportResponse } from "../../../../models/response/userSupport";
import { SelectChangeEvent } from "@mui/material/";

export default function UserProfile() {
  const [data, setData] = useState<UserSupportResponse>({
    rows: [],
    isReady: null,
  });
  const [currentPagiPage, setCurrentPagiPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(5);

  const getUserTickets = async () => {
    const response = (await newAxios(
      "/app/support/messages",
      "GET"
    )) as UserSupportResponse;
    if (response.status === 200 && Array.isArray(response.data)) {
      let userInvitations = response.data.map((user, index) => {
        return createData(
          user.created_date,
          user.subject,
          ticketStatus(user.status),
          <Button
            href={"/app/settings/support/tickets/" + user.unique_token}
            className="form-button"
            size="small"
            variant="contained"
            color="inherit"
          >
            Megtekint
          </Button>
        );
      });
      setData({
        rows: userInvitations,
        isReady: true,
      });
    } else {
      setData({
        rows: [],
        isReady: false,
      });
    }
  };

  function paginationHandler(e: React.ChangeEvent<unknown>, page: number) {
    setCurrentPagiPage(page);
  }

  const itemPerPageHandler = (event: SelectChangeEvent<string>) => {
    const target = event.target;
    if (typeof target.value === "number") {
      setItemPerPage(target.value);
      setCurrentPagiPage(1);
    }
  };

  useEffect(() => {
    getUserTickets();
  }, []);
  if (data.isReady === true) {
    return (
      <Box
        className="settings-content"
      >
        <Navigation />
        <Container
          sx={{ marginBottom: 5, padding: "1rem 0.5rem" }}
          maxWidth="md"
        >
          <Typography mb={3} variant="h5" sx={{ fontWeight: "bold" }}>
            {HU.support_settings.messages.myTicketTitle}
          </Typography>
          {/* if more invited users than 5 show pagination */}
          {Math.ceil(
            data.rows !== undefined ? data.rows.length / itemPerPage : 0
          ) > 1 && (
            <Pagination
              className="pagination"
              sx={{ float: "left" }}
              count={Math.ceil(
                data.rows !== undefined ? data.rows.length / itemPerPage : 0
              )}
              onChange={paginationHandler}
            />
          )}
          <FormControl
            sx={{ float: "right" }}
            className="support_messages_selector"
          >
            <Select
              value={itemPerPage.toString()}
              onChange={itemPerPageHandler}
              autoWidth
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
            </Select>
          </FormControl>
          <TableContainer className="table_container" component={Paper}>
            <Table>
              <TableHead>
                <TableRow className="table_header">
                  <StyledTableCell>
                    {HU.support_settings.messages.ticketCreated}
                  </StyledTableCell>
                  <StyledTableCell>
                    {HU.support_settings.messages.ticketSubject}
                  </StyledTableCell>
                  <StyledTableCell>
                    {HU.support_settings.messages.ticketStatus}
                  </StyledTableCell>
                  <StyledTableCell>
                    {HU.support_settings.messages.ticketHandler}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.rows !== undefined &&
                  data.rows
                    .slice(
                      currentPagiPage * itemPerPage - itemPerPage,
                      currentPagiPage * itemPerPage
                    )
                    .map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell
                          className="table_cell"
                        >
                          {row.Created}
                        </StyledTableCell>
                        <StyledTableCell className="table_cell">
                          {row.Subject}
                        </StyledTableCell>
                        <StyledTableCell
                          className={getStatusClassName(row.Status)}
                        >
                          {row.Status}
                        </StyledTableCell>
                        <StyledTableCell
                          className="table_cell"
                        >
                          {row.Button}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Box>
    );
  } else if (data.isReady === false) {
    return <Navigate to="/app" />;
  } else {
    return (
      <Box className="settings-content">
        <Navigation />
      </Box>
    );
  }
}

function createData(
  Created: string,
  Subject: string,
  Status: string,
  Button: JSX.Element
) {
  return { Created, Subject, Status, Button };
}

function ticketStatus(status: number) {
  if (status === 1) {
    return HU.support_settings.messages.ticketStatusNull;
  } else if (status === 0) {
    return HU.support_settings.messages.ticketStatusOne;
  } else {
    return HU.support_settings.messages.ticketStatusTwo;
  }
}

function getStatusClassName(status: string) {
  if (status === HU.support_settings.messages.ticketStatusNull) {
    return "ticket_status_zero";
  } else if (status === HU.support_settings.messages.ticketStatusOne) {
    return "ticket_status_one";
  } else if (status === HU.support_settings.messages.ticketStatusTwo) {
    return "ticket_status_two";
  }
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
