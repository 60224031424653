import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import newAxios from "../../axios/axios";
import { StatusResponse } from "../../models/response/status";

export default function UserActivation() {
  const [message, setMessage] = useState("");
  const redirect = useNavigate();
  const { ref_id } = useParams();

  const getActivation = async () => {
    const response = await newAxios("/activation/" + ref_id, "get") as StatusResponse;
    const response_msg = document.getElementById("response_msg") as HTMLElement;
    setTimeout(() => {
      redirect("/login");
    }, 3000);
    if (response.status === 200 && response.data.success) {
      response_msg.classList.add("response_msg_success");
      setMessage(response.data.msg);
    } else {
      setMessage(response.data.msg);
    }
  };
  useEffect(() => {
    getActivation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        minHeight: "80vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Container component="main" maxWidth="sm">
          <div className="activation_container">
            <Typography id="response_msg" sx={{ fontWeight: "bold" }}>
              {message}
            </Typography>
          </div>
        </Container>
      </Box>
    </Box>
  );
}
