import React from "react";

import { Typography, Box, Avatar, Grid } from "@mui/material";
import SentencePlayButton from "./sentencePlayButtons";

export default function ActiveHelp(props: any) {
  const { tasks, tasksStatus } = props;
  return (
    <Grid textAlign={"center"} mb={1} item xs={12}>
      <Box mt={1}>
        {/* {tasks[tasksStatus.currentTask].image !== null && (
          <Avatar
            alt="help-image"
            src={tasks[tasksStatus.currentTask].image}
            sx={{
              margin: "auto",
              minHeight: "150px",
              minWidth: "150px",
            }}
          />
        )} */}
        <SentencePlayButton tasks={tasks} tasksStatus={tasksStatus} />
        <Typography mt={1} fontWeight={600}>
          {tasks[tasksStatus.currentTask].eng_sent}
        </Typography>
        <Typography mt={1} fontWeight={600}>
          {tasks[tasksStatus.currentTask].hun_sent}
        </Typography>
      </Box>
    </Grid>
  );
}
