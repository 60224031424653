import { useState, useEffect } from "react";
import { useParams, Navigate, useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";

import newAxios from "../../../axios/axios";
import SubmitButton from "../../../components/ui/submitButton";

import HU from "../../../languages/HU/hu";
import { InvitationResponse } from "../../../models/response/invitation";
import { StatusResponse } from "../../../models/response/status";

export default function Invitation() {
  const [loadingButton, setLoadingButton] = useState(false);
  const [refererUserName, setRefererUserName] = useState("");
  const [invitedEmail, setInvitedEmail] = useState("");
  const [isTokenValid, setTokenStatus] = useState<null | boolean>(null);
  const redirect = useNavigate();
  // get the tokenid from route params
  const { ref_id } = useParams();
  //Check token is valid or forgot
  const checkRefLink = async () => {
    const response = (await newAxios(
      "/invitation/" + ref_id,
      "get"
    )) as InvitationResponse;
    if (response.status === 200 && response.data.isValid) {
      setRefererUserName(response.data.username);
      setInvitedEmail(response.data.email);
      setTokenStatus(true);
    } else if (response.status === 200 && !response.data.isValid) {
      setTokenStatus(false);
    } else if (response.status > 200) {
      setTokenStatus(false);
    }
  };

  useEffect(() => {
    checkRefLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function userRegister(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoadingButton(true);
    const [username, email, password, password2, response_msg] =
      document.querySelectorAll(
        "#username, #email, #password, #password2, #response_msg"
      ) as unknown as [...HTMLInputElement[]];
    const response = (await newAxios("/register", "post", {
      username: username.value,
      email: email.value,
      password: password.value,
      password2: password2.value,
      refererUserName,
      invitation_ref: ref_id
    })) as StatusResponse;
    response_msg.textContent = "";
    response_msg.classList.remove("response_msg_success");
    setLoadingButton(false);
    if (response.status === 200 && response.data.success) {
      username.value = "";
      email.value = "";
      password.value = "";
      password2.value = "";
      response_msg.classList.add("response_msg_success");
      response_msg.textContent = response.data.msg;
      setTimeout(() => {
        redirect("/login");
      }, 3000);
    } else {
      response_msg.textContent = response.data.msg;
    }
  }

  if (isTokenValid === false) {
    return <Navigate to="/login" />;
  } else if (isTokenValid === true) {
    return (
      <Box
        sx={{
          minHeight: "80vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Container component="main" maxWidth="sm">
            <Typography component="h1" variant="h5" sx={{ fontWeight: "bold" }}>
              {refererUserName + " " + HU.forms.invitation.title}
            </Typography>
            <Divider className="form-divider" />
            <form onSubmit={userRegister}>
              <TextField
                className="form_input_field"
                margin="normal"
                required
                fullWidth
                id="username"
                name="username"
                type="text"
                autoFocus
                placeholder={HU.forms.invitation.usernameFieldPlaceholder}
                InputProps={{
                  autoComplete: "off",
                  startAdornment: (
                    <IconButton disabled tabIndex={-1}>
                      <InsertEmoticonIcon />
                    </IconButton>
                  ),
                }}
              />
              <TextField
                className="form_input_field"
                margin="normal"
                value={invitedEmail}
                required
                fullWidth
                id="email"
                name="email"
                type="email"
                placeholder={HU.forms.invitation.emailFieldPlaceholder}
                InputProps={{
                  autoComplete: "email",
                  startAdornment: (
                    <IconButton disabled tabIndex={-1}>
                      <EmailIcon />
                    </IconButton>
                  ),
                }}
              />
              <TextField
                className="form_input_field"
                margin="normal"
                required
                fullWidth
                name="password"
                id="password"
                type="password"
                placeholder={HU.forms.invitation.passwordFieldPlaceholder}
                autoComplete="new-password"
                InputProps={{
                  autoComplete: "new-password",
                  startAdornment: (
                    <IconButton disabled tabIndex={-1}>
                      <LockIcon />
                    </IconButton>
                  ),
                }}
              />
              <TextField
                className="form_input_field"
                margin="normal"
                required
                fullWidth
                name="password2"
                id="password2"
                type="password"
                placeholder={HU.forms.invitation.password2FieldPlaceholder}
                autoComplete="new-password"
                InputProps={{
                  autoComplete: "new-password",
                  startAdornment: (
                    <IconButton disabled tabIndex={-1}>
                      <LockIcon />
                    </IconButton>
                  ),
                }}
              />
              <SubmitButton
                isLoading={loadingButton}
                buttonName={HU.forms.invitation.submitButton}
              />
            </form>
          </Container>
        </Box>
      </Box>
    );
  } else {
    return null;
  }
}
