import React from "react";
import moment from "moment";
import "moment/locale/hu";

import { useState, useEffect } from "react";
import { useParams, Navigate } from "react-router-dom";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Avatar } from "@mui/material";

import SubmitButton from "../../../../components/ui/submitButton";
import newAxios from "../../../../axios/axios";

import HU from "../../../../languages/HU/hu";
import { StatusResponse } from "../../../../models/response/status";
import {
  UserTicketStatusResponse,
  UserTicketMessagesResponse,
} from "../../../../models/response/userTicketMessages";

interface TransitionProps {
  children: React.ReactElement<any, any>;
}
const Transition = React.forwardRef((props: TransitionProps, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function UserTicket() {
  const [userTickets, setUserTickets] = useState<UserTicketStatusResponse>({
    ticket: {},
    messages: [],
    isReady: null,
    currentUser: null,
    avatar: null,
  });
  const [dataChanged, setDataChanged] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const { ticket_token } = useParams();
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [newMessageLength, setNewMessageLength] = useState(0);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };
  async function newTicketMessage(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoadingButton(true);
    const [message, response_msg] = document.querySelectorAll(
      "#message, #response_msg"
    ) as unknown as [...HTMLInputElement[]];
    const messageValue = message.value;
    const response = (await newAxios(
      "/app/settings/mytickets/newmessage/" + ticket_token,
      "POST",
      {
        message: messageValue,
      }
    )) as StatusResponse;
    setLoadingButton(false);
    response_msg.classList.remove("response_msg_success");
    if (response.status === 200 && response.data.success) {
      const currentDate = new Date();
      const momentDate = moment(currentDate)
        .locale("hu")
        .startOf("seconds")
        .fromNow();
      response_msg.classList.add("response_msg_success");
      response_msg.textContent = response.data.msg;
      // set button to disabled
      setDataChanged(false);
      // update ticket messages data with new
      setUserTickets((prevState: UserTicketStatusResponse) => ({
        ...prevState,
        messages: [
          ...prevState.messages,
          // [...(prevState.messages !== undefined ? prevState.messages : [prevState.messages])],
          {
            created_date: momentDate,
            message: messageValue,
            username: userTickets.currentUser,
            avatar: response.data.avatar,
            isSupport: response.data.isSupport,
          },
        ],
      }));
      message.value = "";
      setNewMessageLength(0);
    } else {
      response_msg.textContent = response.data.msg;
    }
  }
  //  if form data length not null set the button to active
  function checkMessageFormInput() {
    const [message, response_msg] = document.querySelectorAll(
      "#message, #response_msg"
    ) as unknown as [...HTMLInputElement[]];
    if (response_msg.textContent !== "") {
      response_msg.textContent = "";
    }
    if (message.value.length > 0) {
      setNewMessageLength(message.value.length);
      setDataChanged(true);
    } else {
      setNewMessageLength(0);
      setDataChanged(false);
    }
  }

  const closeTheTicket = async () => {
    const response = (await newAxios(
      "/app/settings/mytickets/close/" + ticket_token,
      "GET"
    )) as StatusResponse;
    if (response.status === 200 && response.data.success) {
      setUserTickets((prevState) => ({
        ...prevState,
        ticket: {
          ...prevState.ticket,
          status: 2,
        },
      }));
      setOpenConfirm(false);
    }
  };
  const getUserProfileData = async () => {
    const response = (await newAxios(
      "/app/settings/mytickets/" + ticket_token,
      "GET"
    )) as UserTicketMessagesResponse;
    if (response.status === 200) {
      setUserTickets({
        ticket: response.data.handledTicket,
        messages: response.data.handledTicketMessages,
        isReady: true,
        currentUser: response.data.currentUser,
        avatar: response.data.avatar,
      });
    } else {
      setUserTickets({
        isReady: false,
      });
    }
  };

  useEffect(() => {
    getUserProfileData();
    // eslint-disable-next-line
  }, []);

  if (userTickets.isReady === false) {
    return <Navigate to="/app/settings/support" />;
  } else if (userTickets.isReady === true) {
    return (
      <div>
        <Box
          className="settings-content"
          sx={{
            minHeight: "80vh",
          }}
        >
          <Container
            className="settings-content-container"
            component="main"
            maxWidth="md"
          >
            <Grid container>
              <Grid className="ticket_container" item xs={12}>
                <Typography variant={"h6"} className="ticket_subject">
                  {userTickets.ticket !== undefined &&
                    userTickets.ticket.subject}
                </Typography>
              </Grid>
            </Grid>
            <Container
              maxWidth="md"
              className="ticket_container"
              sx={{
                border:
                  userTickets.ticket !== undefined &&
                  userTickets.ticket.status === 2
                    ? "1px solid red!important"
                    : undefined,
              }}
            >
              <Grid container>
                <Grid className="ticket_left_container" item xs={12} sm={3}>
                  <Avatar
                    alt="avatar"
                    className="ticketAvatar"
                    src={
                      userTickets.ticket !== undefined
                        ? userTickets.ticket.avatar
                        : undefined
                    }
                  />
                  <Typography className="ticket_username">
                    {userTickets.ticket !== undefined &&
                      userTickets.ticket.username}
                  </Typography>
                  <Divider className="form-divider" />
                  <Typography className="ticket_meta">
                    Létrehozva:{" "}
                    {userTickets.ticket !== undefined &&
                      userTickets.ticket.created_date}
                  </Typography>
                  <Divider className="form-divider" />
                </Grid>
                <Grid className="ticket_right_container" item xs={12} sm={9}>
                  <Typography align="left" className="ticket_subject_message">
                    {userTickets.ticket !== undefined &&
                      userTickets.ticket.message}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent={"flex-end"}>
                {userTickets.ticket !== undefined &&
                userTickets.ticket.status !== 2 ? (
                  <Button
                    className="danger-button"
                    sx={{ marginBottom: "1rem" }}
                    type="submit"
                    size="small"
                    variant="contained"
                    onClick={handleOpenConfirm}
                  >
                    {HU.user_settings.support.openedTicket.closeButton}
                  </Button>
                ) : (
                  <Button
                    className="danger-button-disabled"
                    type="submit"
                    size="small"
                    variant="contained"
                    onClick={handleOpenConfirm}
                    disabled
                  >
                    {HU.user_settings.support.openedTicket.closeButtonDisabled}
                  </Button>
                )}
                <Dialog
                  open={openConfirm}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleCloseConfirm}
                >
                  <Container className="confirm_dialog_container">
                    <DialogTitle>
                      {HU.user_settings.support.openedTicket.confirmTitle}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText
                        color={"inherit"}
                        id="alert-dialog-slide-description"
                      >
                        {HU.user_settings.support.openedTicket.confirmDetails}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        className="danger-button"
                        size="small"
                        fullWidth
                        variant="contained"
                        onClick={handleCloseConfirm}
                      >
                        {HU.user_settings.support.openedTicket.confirmNo}
                      </Button>
                      <Button
                        className="form-button"
                        size="small"
                        fullWidth
                        variant="contained"
                        onClick={closeTheTicket}
                      >
                        {HU.user_settings.support.openedTicket.confirmYes}
                      </Button>
                    </DialogActions>
                  </Container>
                </Dialog>
              </Grid>
            </Container>
            {userTickets.messages !== undefined &&
              userTickets.messages.map((childMessage: any, index: number) => {
                return (
                  <div
                    key={index}
                    className={
                      userTickets.ticket !== undefined &&
                      childMessage.username === userTickets.ticket.username
                        ? "ticket_owner_container"
                        : "ticket_support_container"
                    }
                  >
                    <Grid mb={3} container>
                      <Grid
                        className="ticket_left_container"
                        item
                        xs={12}
                        sm={3}
                      >
                        <Avatar
                          alt="avatar"
                          className="ticketAvatar"
                          src={
                            userTickets.ticket !== undefined
                              ? userTickets.ticket.avatar
                              : undefined
                          }
                        />
                        {childMessage.isSupport && (
                          <Typography className="ticket_support_title">
                            - [ Support ] -
                          </Typography>
                        )}
                        <Typography className="ticket_username">
                          {childMessage.username}
                        </Typography>
                        <Divider className="form-divider" />
                        <Typography className="ticket_meta">
                          Elküldve: {childMessage.created_date}
                        </Typography>
                        <Divider className="form-divider" />
                      </Grid>
                      <Grid
                        className="ticket_right_container"
                        item
                        xs={12}
                        sm={9}
                      >
                        <Typography align="left" className="ticket_message">
                          {childMessage.message}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                );
              })}
            {userTickets.ticket !== undefined &&
              userTickets.ticket.status !== 2 && (
                <Container
                  sx={{ border: "1px solid white", borderRadius: "15px" }}
                >
                  <Typography
                    sx={{ float: "left", paddingTop: 2, fontWeight: "bold" }}
                  >
                    {HU.user_settings.support.openedTicket.replyTitle}
                  </Typography>
                  <Divider className="form-divider" sx={{ width: "100%" }} />
                  <form onSubmit={newTicketMessage}>
                    <TextField
                      className="form_input_field"
                      onChange={checkMessageFormInput}
                      margin="normal"
                      fullWidth
                      multiline
                      rows={8}
                      name="message"
                      id="message"
                      type="text"
                      placeholder={
                        HU.user_settings.support.openedTicket
                          .messageFieldPlaceholder
                      }
                      InputProps={{
                        startAdornment: (
                          <Typography
                            m={1}
                            tabIndex={-1}
                            className="ticketFormEndLineMessage"
                          >
                            {newMessageLength > 0 &&
                              newMessageLength + " / 1000"}
                          </Typography>
                        ),
                      }}
                      required
                    />
                    <SubmitButton
                      isLoading={loadingButton}
                      buttonName={
                        HU.user_settings.support.openedTicket.submitButton
                      }
                      dataChanged={dataChanged}
                    />
                  </form>
                </Container>
              )}
          </Container>
        </Box>
      </div>
    );
  } else {
    return null;
  }
}
