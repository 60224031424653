import { useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import EmailIcon from "@mui/icons-material/Email";

import newAxios from "../../../axios/axios";
import SubmitButton from "../../../components/ui/submitButton";

import HU from "../../../languages/HU/hu";
import { StatusResponse } from "../../../models/response/status";

export default function Forgot() {
  const [loadingButton, setLoadingButton] = useState(false);

  async function userForgot(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoadingButton(true);
    const email = document.getElementById("email") as HTMLInputElement;
    const response_msg = document.getElementById("response_msg") as HTMLElement;
    const response = (await newAxios("/forgot", "post", {
      email: email.value,
    })) as StatusResponse;
    response_msg.textContent = "";
    response_msg.classList.remove("response_msg_success");
    setLoadingButton(false);
    if (response.status === 200 && response.data.success) {
      email.value = "";
      response_msg.classList.add("response_msg_success");
      response_msg.textContent = response.data.msg;
      // set isLogged value === true and redirect to protected route
    } else {
      response_msg.textContent = response.data.msg;
    }
  }
  return (
    <Box
      sx={{
        minHeight: "80vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Container component="main" maxWidth="sm">
          <Typography
            className="h1-titles"
            component="h1"
            variant="h5"
            sx={{ fontWeight: "bold" }}
          >
            {HU.forms.forgot.title}
          </Typography>
          <Divider className="form-divider" />
          <Typography mt={2} sx={{ fontWeight: "bold" }}>
            {HU.forms.forgot.infoMsg}
          </Typography>
          <form onSubmit={userForgot}>
            <TextField
              className="form_input_field"
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              type="email"
              placeholder={HU.forms.forgot.emailFieldPlaceholder}
              autoComplete="email"
              autoFocus
              InputProps={{
                startAdornment: (
                  <IconButton disabled tabIndex={-1}>
                    <EmailIcon />
                  </IconButton>
                ),
              }}
            />
            <SubmitButton
              isLoading={loadingButton}
              buttonName={HU.forms.forgot.submitButton}
            />
            <div className="form_links">
              <span>{HU.forms.forgot.signInMessage} </span>
              <Link className="links" href="/login" variant="body2">
                {HU.forms.forgot.signInLink}
              </Link>
            </div>
            <span>{HU.forms.forgot.signUpMessage} </span>
            <Link className="links" href="/register" variant="body2">
              {HU.forms.forgot.signUpLink}
            </Link>
          </form>
        </Container>
      </Box>
    </Box>
  );
}
