import { Grid, Typography, Divider } from "@mui/material";
import { useState, useEffect } from "react";
import moment from "moment";
import { indexUserData } from "../../../../../../models/response/afterLogin/indexUserData";
export default function UserHomeworkStatus(props: { user: indexUserData }) {
  const [isHomeworkStatusReady, setIsHomeworkStatusReady] = useState<
    JSX.Element | JSX.Element[] | undefined
  >(undefined);
  const getUserHomeworkInfo = (): JSX.Element | JSX.Element[] | undefined => {
    if (!props.user.isReady) {
      return;
    }
    // if the user did not learn any words
    if (props.user.data?.discovered_words === 0) {
      return (
        <Grid m={"auto"} mt={1} pl={1} xs={12} item>
          <Typography
            m={"2px auto"}
            sx={{ color: "#b1404d", display: "block" }}
          >
            Még nem fedeztél fel új szavakat!
          </Typography>
        </Grid>
      );
    }
    // if first learn is finished but the homework not ready (new user)
    else if (
      props.user.data?.homework_mistakes === 2 &&
      props.user.data?.homework_old_words === 2
    ) {
      const homeworkData = props.user.data?.homeworks_data?.map((item, i) => {
        if (item.task_name === "homework_mistakes") {
          return (
            <Grid key={i} m={"auto"} mt={1} pl={1} xs={12} item>
              <Typography component="span">Gyakori hibák</Typography>
              <Typography>
                Első lecke -&nbsp;
                {moment(item.next_task).startOf("seconds").fromNow()}
              </Typography>
            </Grid>
          );
        } else if (item.task_name === "homework_old_words") {
          return (
            <Grid key={i} m={"auto"} mb={1} mt={1} pl={1} xs={12} item>
              <Typography component="span">Régen gyakorolt</Typography>
              <Typography>
                Első lecke -&nbsp;
                {moment(item.next_task).startOf("seconds").fromNow()}
              </Typography>
            </Grid>
          );
        } else {
          return <div key={i}></div>;
        }
      });
      return homeworkData;
    }
    // if all homework finished
    else if (
      props.user.data?.homework_mistakes === 1 &&
      props.user.data?.homework_old_words === 1
    ) {
      const homeworkData = props.user.data?.homeworks_data?.map((item, i) => {
        if (item.task_name === "homework_mistakes") {
          return (
            <Grid key={i} m={"auto"} mt={1} pl={1} xs={12} item>
              <Typography component="span">Gyakori hibák -</Typography>
              <Typography component="span" sx={{ color: "var(--myNewGreen)" }}>
                &nbsp;Befejezve!
              </Typography>
              <Typography>
                Következő lecke -&nbsp;
                {moment(item.next_task).startOf("seconds").fromNow()}
              </Typography>
            </Grid>
          );
        } else if (item.task_name === "homework_old_words") {
          return (
            <Grid key={i} m={"auto"} mb={1} mt={1} pl={1} xs={12} item>
              <Typography component="span">Régen gyakorolt -</Typography>
              <Typography component="span" sx={{ color: "var(--myNewGreen)" }}>
                &nbsp;Befejezve!
              </Typography>
              <Typography>
                Következő lecke -&nbsp;
                {moment(item.next_task).startOf("seconds").fromNow()}
              </Typography>
            </Grid>
          );
        } else {
          return <div key={i}></div>;
        }
      });
      return homeworkData;
    }
    // if one of homework is ready
    else {
      const homeworkData = props.user.data?.homeworks_data?.map((item, i) => {
        if (item.task_name === "homework_mistakes") {
          const mistakesHomework =
            props.user.data?.homework_mistakes === 0 ? (
              <Grid key={i} m={"auto"} mt={0.5} pl={1} xs={12} item>
                <Typography component="span">Gyakori Hibák -</Typography>
                <Typography
                  component="span"
                  sx={{ color: "var(--myNewGreen)"}}
                >
                  &nbsp;Elérhető!
                </Typography>
              </Grid>
            ) : (
              <Grid key={i} m={"auto"} mb={1} pl={1} xs={12} item>
                <Typography component="span">Gyakori Hibák -</Typography>
                <Typography
                  component="span"
                  sx={{ color: "var(--myNewGreen)" }}
                >
                  &nbsp;Befejezve!
                </Typography>
                <Typography>
                  Következő lecke -&nbsp;
                  {moment(item.next_task).startOf("seconds").fromNow()}
                </Typography>
              </Grid>
            );
          return (
            <Grid key={i} m={"auto"} mt={1} pl={1} xs={12} item>
              {mistakesHomework}
            </Grid>
          );
        } else if (item.task_name === "homework_old_words") {
          const oldWordsHomework =
            props.user.data?.homework_old_words === 0 ? (
              <Grid key={i} m={"auto"} mb={1} pl={1} xs={12} item>
                <Typography component="span">Régen gyakorolt -</Typography>
                <Typography
                  component="span"
                  sx={{ color: "var(--myNewGreen)" }}
                >
                  &nbsp;Elérhető!
                </Typography>
              </Grid>
            ) : (
              <Grid key={i} m={"auto"} mb={1} pl={1} xs={12} item>
                <Typography component="span">Régen gyakorolt -</Typography>
                <Typography
                  component="span"
                  sx={{ color: "var(--myNewGreen)" }}
                >
                  &nbsp;Befejezve!
                </Typography>
                <Typography>
                  Következő lecke -&nbsp;
                  {moment(item.next_task).startOf("seconds").fromNow()}
                </Typography>
              </Grid>
            );
          return (
            <Grid key={i} m={"auto"} mt={1} pl={1} xs={12} item>
              {oldWordsHomework}
            </Grid>
          );
        } else {
          return <div key={i}></div>;
        }
      });
      return homeworkData;
    }
  };

  useEffect(() => {
    if (props.user.isReady && isHomeworkStatusReady === undefined) {
      const homeworkInfo = getUserHomeworkInfo();
      setIsHomeworkStatusReady(homeworkInfo);
    }
    // eslint-disable-next-line
  }, [props.user.isReady, isHomeworkStatusReady]);
  return (
    <Grid mt={3} container sx={{ textAlign: "center" }}>
      <Divider sx={{ width: "80%" }} className="form-divider" />
      <Typography m={"auto"} mt={1.5} sx={{ color: "gold" }}>
        - Leckék állapota -
      </Typography>
      {isHomeworkStatusReady}
    </Grid>
  );
}
