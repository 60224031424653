import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import newAxios from "../../../../../axios/axios";
import { WordCategoryTasksType } from "../../../../../models/response/afterLogin/practice/wordCategoryTasks";
import Practise from "../../../../../components/practice/practise";
import RewardPage from "../../../../../components/practice/rewardPage";

export default function SrsWordsIndex() {
  const target = "srs_practice";
  const [tasks, setTasks] = useState<Array<WordCategoryTasksType>>();
  const [finishedTasks, setFinishedTasks] = useState([]);
  const redirect = useNavigate();
  const [isActiveStudyTimer, setIsActiveStudyTimer] = useState(false);
  const [studyTimeInSeconds, setStudyTimeInSeconds] = useState(0);
  const [intervalID, setIntervalID] = useState<number | null>(null);
  const [tasksStatus, setTasksStatus] = useState({
    isReady: false,
    type: 0, // 0 eng to hun 1 hun to eng, 2 tasks are finished go to reward page
    currentTask: 0, // current tasks[currentTask] array
    currentProgress: 0,
    currentForm: 0, // 0 default, 1, good answer, 2 bad answer
    userAnswer: "", // user input answer from answerForm
    engToHunTasks: 0, // check how many eng to hun tasks was
    hunToEngTasks: 0, // check how many hun to eng tasks was
    responseMsg: "", // reponse msg from answer check handler
    totalGoodAnswer: 0, // totals of good answer from the tasks
    totalWrongAnswer: 0, // totals of wrong answer from the tasks
    smallMistakes: 0, // totals of small mistakes from the tasks
    successRate: 0, // tasks success rate
    totalTry: 0, // it will count the total of good and wrong answer
    totalStudyTimeInSeconds: 0,
  });

  const getWordPracticeTasks = async () => {
    const response = (await newAxios("/app/practice/srs/", "GET")) as any;
    if (response.status === 200 && response.data.isSuccess) {
      const getType = Math.round(Math.random() * 1); // 0 eng to hun , 1 hun to eng
      setTasks(response.data.allTasks);
      setTasksStatus((prevState) => ({
        ...prevState,
        isReady: true,
        type: getType,
        engToHunTasks: getType === 0 ? 1 : 0,
        hunToEngTasks: getType === 1 ? 1 : 0,
      }));
    } else {
      return redirect("/app/");
    }
  };

  // start counting the task study time after a new task started
  useEffect(() => {
    if (
      !isActiveStudyTimer &&
      intervalID === null &&
      (tasksStatus.type === 0 || tasksStatus.type === 1)
    ) {
      const startCountStudyTime = () => {
        setStudyTimeInSeconds((prevTime) => {
          return prevTime + 1;
        });
      };
      const newIntervalID = window.setInterval(startCountStudyTime, 1000);
      setIntervalID(newIntervalID);
      setIsActiveStudyTimer(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveStudyTimer]);

  // update the total study time with a previous studied time and start a new timer
  useEffect(() => {
    if (tasksStatus.type === 2 && intervalID !== null) {
      clearInterval(intervalID);
      setTasksStatus((prevState) => ({
        ...prevState,
        totalStudyTimeInSeconds:
          prevState.totalStudyTimeInSeconds + studyTimeInSeconds,
      }));
    }
    if (isActiveStudyTimer && intervalID !== null) {
      clearInterval(intervalID);
      setIntervalID(null);
      setTasksStatus((prevState) => ({
        ...prevState,
        totalStudyTimeInSeconds:
          prevState.totalStudyTimeInSeconds + studyTimeInSeconds,
      }));
      setStudyTimeInSeconds(0);
      setIsActiveStudyTimer(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasksStatus.totalTry, tasksStatus.type]);

  // if the user hit the maximum allowed study time / task stop counting
  useEffect(() => {
    if (studyTimeInSeconds === 60 && intervalID !== null) {
      clearInterval(intervalID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyTimeInSeconds]);

  useEffect(() => {
    getWordPracticeTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentType = () => {
    if (tasksStatus.type <= 1) {
      return (
        <Practise
          tasks={tasks}
          setTasks={setTasks}
          tasksStatus={tasksStatus}
          setTasksStatus={setTasksStatus}
          finishedTasks={finishedTasks}
          setFinishedTasks={setFinishedTasks}
        />
      );
    } else {
      return (
        <RewardPage
          finishedTasks={finishedTasks}
          tasksStatus={tasksStatus}
          target={target}
        />
      );
    }
  };

  if (tasksStatus.isReady && typeof tasks !== "undefined") {
    return (
      <Grid className="practice_words_index_container" container p={1}>
        <Grid className="practice_words_index_box" xs={12} item>
          <Grid container justifyContent="center">
            {getCurrentType()}
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container p={1}>
        <Grid className="practice_words_index_container" xs={12} item>
          <Box textAlign={"center"} className="practice_words_index_box">
            <CircularProgress size={"5rem"} />
          </Box>
        </Grid>
      </Grid>
    );
  }
}
