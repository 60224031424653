import React from "react";
import { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/hu";

import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { Link } from "@mui/material";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EmailIcon from "@mui/icons-material/Email";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";

import Navigation from "./navigation";
import SubmitButton from "../../../../components/ui/submitButton";
import newAxios from "../../../../axios/axios";

import HU from "../../../../languages/HU/hu";
import { StatusResponse } from "../../../../models/response/status";
import { InvitedTable, InvitedUsers } from "../../../../models/invited";

interface TransitionProps {
  children: React.ReactElement<any, any>;
}
const Transition = React.forwardRef((props: TransitionProps, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function UserInvite() {
  const [loadingButton, setLoadingButton] = useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [invitation_ref, setInvitation_ref] = React.useState("");
  const [invitations, setInvitations] = useState(0);
  const [data, setData] = useState<InvitedTable>({
    rows: [],
    isReady: false,
  });
  const [currentPagiPage, setCurrentPagiPage] = useState(1);
  const [successReward, setSuccessReward] = useState(false);
  const itemPerPage = 5;

  async function userInvite(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoadingButton(true);
    const email = document.getElementById("email") as HTMLFormElement;
    const emailValue = email.value;
    const response_msg = document.getElementById("response_msg") as HTMLElement;
    const response = await newAxios("/app/settings/invite", "post", {
      email: emailValue,
    }) as any;
    response_msg.textContent = "";
    response_msg.classList.remove("response_msg_success");
    setLoadingButton(false);
    if (response.status === 200 && response.data.success) {
      const currentDate = new Date();
      const momentDate = moment(currentDate)
        .locale("hu")
        .startOf("seconds")
        .fromNow();
      email.value = "";
      response_msg.classList.add("response_msg_success");
      response_msg.textContent = response.data.msg;
      // update user invitation list with new
      setData((prevState: InvitedTable) => ({
        ...prevState,
        rows: [
          {
            Operation: cancelInvitationButton(0, response.data.invitation_ref),
            Invited: momentDate,
            Email: emailValue,
            Registered: HU.user_settings.invite.table.no,
            Subscription: HU.user_settings.invite.table.no,
            Reward: rewardStatus(0, ""),
          },
          ...prevState.rows,
        ],
      }));
      setInvitations(invitations - 1);
    } else {
      response_msg.textContent = response.data.msg;
    }
  }

  const handleOpenConfirm = (invitation_ref: string) => {
    setInvitation_ref(invitation_ref);
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const cancelUserInvitation = async () => {
    const response_msg = document.getElementById("response_msg") as HTMLElement;
    const response = await newAxios("/app/settings/invite/cancel", "post", {
      invitation_ref: invitation_ref,
    }) as any;
    if (response.status === 200 && response.data.success) {
      response_msg.classList.add("response_msg_success");
      response_msg.textContent = response.data.msg;
      getUserInvitationsData()
      setOpenConfirm(false);
    } else {
      response_msg.textContent = response.data.msg;
      setOpenConfirm(false);
    }
  };

  const getUserInvitationsData = async () => {
    const response = await newAxios(
      "/app/settings/invite",
      "GET"
    ) as InvitedUsers;
    if (response.status === 200) {
      let userInvitations = response.data.userInvites.map((user) => {
        const invitedUser = createData(
          cancelInvitationButton(user.is_Registered, user.invitation_ref),
          user.invited_date,
          user.invited_email,
          user.is_Registered === 1
            ? HU.user_settings.invite.table.yes
            : HU.user_settings.invite.table.no,
          user.subscription === 1
            ? HU.user_settings.invite.table.yes
            : HU.user_settings.invite.table.no,
          rewardStatus(user.reward, user.reward_ref)
        );
        return invitedUser;
      });
      setInvitations(response.data.invitations);
      setData({
        rows: userInvitations,
        isReady: true,
      });
    }
  };

  useEffect(() => {
    getUserInvitationsData();
    // eslint-disable-next-line
  }, []);

  function paginationHandler(e: React.ChangeEvent<unknown>, page: number) {
    setCurrentPagiPage(page);
  }

  async function getRewardHandler(e: React.MouseEvent<HTMLButtonElement>) {
    let button = e.target as HTMLButtonElement;
    let reward_ref = button.value;
    const response = (await newAxios(
      "/app/settings/invite/getreward/" + reward_ref,
      "GET"
    )) as StatusResponse;
    if (response.status === 200 && response.data.success) {
      getUserInvitationsData();
      setSuccessReward(true);
      setTimeout(() => {
        setSuccessReward(false);
      }, 5000);
    }
  }

  function cancelInvitationButton(
    isRegistered: number,
    invitation_ref: string
  ): JSX.Element | null {
    if (isRegistered === 0) {
      return (
        <Link
          onClick={() => {
            handleOpenConfirm(invitation_ref);
          }}
        >
          <DeleteForeverOutlinedIcon
            sx={{ color: "var(--myAlert)", cursor: "pointer!important" }}
          ></DeleteForeverOutlinedIcon>
        </Link>
      );
    } else {
      return (
        <CheckCircleOutlineOutlinedIcon sx={{ color: "var(--myNewGreen)" }} />
      );
    }
  }

  function rewardStatus(reward: number, reward_ref: string): JSX.Element {
    if (reward === 0) {
      return (
        <Button
          className="form-button"
          variant="contained"
          size="small"
          disabled
        >
          {HU.user_settings.invite.rewardButtons.notYet}
        </Button>
      );
    } else if (reward === 1) {
      return (
        <Button
          className="form-button"
          value={reward_ref}
          onClick={getRewardHandler}
          variant="contained"
          size="small"
        >
          {HU.user_settings.invite.rewardButtons.collectible}
        </Button>
      );
    } else {
      return (
        <Button
          className="form-button"
          variant="contained"
          size="small"
          disabled
        >
          {HU.user_settings.invite.rewardButtons.collected}
        </Button>
      );
    }
  }

  if (!data.isReady) {
    return (
      <Box className="settings-content">
        <Navigation />
      </Box>
    );
  } else {
    return (
      <Box className="settings-content">
        <Navigation />
        <Container
          className="settings-content-container"
          component="main"
          maxWidth="sm"
        >
          <Typography component="h1" variant="h5">
            {HU.user_settings.invite.title}
          </Typography>
          <Typography
            my={1}
            component="p"
            color={"orange"}
            sx={{ fontSize: 12 }}
          >
            {HU.user_settings.invite.infoText}
          </Typography>
          <Divider className="form-divider" />
          <Typography sx={{ fontWeight: "bold" }}>
            Elérhető meghívók: {invitations}
          </Typography>
          <form onSubmit={userInvite}>
            <TextField
              className="form_input_field"
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              type="email"
              placeholder={HU.user_settings.invite.emailFieldPlaceholder}
              autoComplete="email"
              autoFocus
              InputProps={{
                startAdornment: (
                  <IconButton disabled tabIndex={-1}>
                    <EmailIcon />
                  </IconButton>
                ),
              }}
            />
            <SubmitButton
              isLoading={loadingButton}
              buttonName={HU.user_settings.invite.submitButton}
            />
          </form>
          <Typography
            mt={1}
            component="h2"
            variant="h6"
            sx={{ textDecoration: "underline!important" }}
          >
            {HU.user_settings.invite.invitedUsersTitle}
          </Typography>
          {successReward && (
            <Typography mt={2} color={"greenyellow"}>
              {HU.user_settings.invite.rewardMsg}
            </Typography>
          )}
        </Container>
        <Container
          sx={{ marginBottom: 5, padding: "1rem 0.5rem" }}
          maxWidth="md"
        >
          {/* if more invited users than 5 show pagination */}
          {Math.ceil(data.rows.length / itemPerPage) > 1 && (
            <Pagination
              className="pagination"
              sx={{ float: "right!important" }}
              count={Math.ceil(data.rows.length / itemPerPage)}
              onChange={paginationHandler}
            />
          )}
          <TableContainer className="table_container" component={Paper}>
            <Table>
              <TableHead>
                <TableRow className="table_header">
                  <StyledTableCell>
                    {HU.user_settings.invite.table.status}
                  </StyledTableCell>
                  <StyledTableCell>
                    {HU.user_settings.invite.table.invitedTitle}
                  </StyledTableCell>
                  <StyledTableCell>
                    {HU.user_settings.invite.table.emailTitle}
                  </StyledTableCell>
                  <StyledTableCell>
                    {HU.user_settings.invite.table.registeredTitle}
                  </StyledTableCell>
                  <StyledTableCell>
                    {HU.user_settings.invite.table.subscriptionTitle}
                  </StyledTableCell>
                  <StyledTableCell>
                    {HU.user_settings.invite.table.rewardTitle}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Invited, Email, Registered, Subscription, Reward */}
                {data.rows
                  .slice(
                    currentPagiPage * itemPerPage - itemPerPage,
                    currentPagiPage * itemPerPage
                  )
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell className="table_cell">
                        {row.Operation}
                      </StyledTableCell>
                      <StyledTableCell className="table_cell">
                        {row.Invited}
                      </StyledTableCell>
                      <StyledTableCell className="table_cell">
                        {row.Email}
                      </StyledTableCell>
                      <StyledTableCell className="table_cell">
                        {row.Registered}
                      </StyledTableCell>
                      <StyledTableCell className="table_cell">
                        {row.Subscription}
                      </StyledTableCell>
                      <StyledTableCell className="table_cell">
                        {row.Reward}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
        <Dialog
          open={openConfirm}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseConfirm}
        >
          <Container className="confirm_dialog_container">
            <DialogTitle>
              {HU.user_settings.invite.cancelInvitation.confirmTitle}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                color={"inherit"}
                id="alert-dialog-slide-description"
              >
                {HU.user_settings.invite.cancelInvitation.confirmDetails}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                className="danger-button"
                size="small"
                fullWidth
                variant="contained"
                onClick={handleCloseConfirm}
              >
                {HU.user_settings.invite.cancelInvitation.confirmNo}
              </Button>
              <Button
                className="form-button"
                size="small"
                fullWidth
                variant="contained"
                onClick={cancelUserInvitation}
              >
                {HU.user_settings.invite.cancelInvitation.confirmYes}
              </Button>
            </DialogActions>
          </Container>
        </Dialog>
      </Box>
    );
  }
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(
  Operation: JSX.Element | null,
  Invited: string,
  Email: string,
  Registered: string,
  Subscription: string,
  Reward: JSX.Element
) {
  return { Operation, Invited, Email, Registered, Subscription, Reward };
}
