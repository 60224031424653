import * as React from "react";
import { Link, matchPath, useLocation } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import Links from "../../../../languages/HU/links";

const pages = Links.supportSettingsNavigation;

function useRouteMatch(patterns: string[]) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }
  return null;
}

export default function Navigation() {
  const getAllRoute = pages.map((page) => page.url);
  const routeMatch = useRouteMatch(getAllRoute);
  const currentTab = routeMatch?.pattern?.path;

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs
        className="settings-navigation-header"
        value={currentTab}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        TabIndicatorProps={{ className: "settings-navigation-tab-indicator" }}
        sx={{ width: "100%", margin: "auto" }}
      >
        {pages.map((page, index) => (
          <Tab
            className="settings-navigation-tab"
            key={index}
            label={page.name}
            value={page.url}
            to={page.url}
            component={Link}
            sx={{ margin: "auto", minWidth: 100 / pages.length + "%" }}
          />
        ))}
      </Tabs>
    </Box>
  );
}
