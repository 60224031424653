import { useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";

import newAxios from "../../../axios/axios";
import SubmitButton from "../../../components/ui/submitButton";

import HU from "../../../languages/HU/hu";
import { UserState } from "../../../models/userState";
import { AvatarResponse } from "../../../models/response/avatar";

export default function Login(props: {
  setUser: React.Dispatch<React.SetStateAction<UserState>>;
  setUserAvatar: React.Dispatch<React.SetStateAction<string>>;
}) {
  const [loadingButton, setLoadingButton] = useState(false);

  async function userLogin(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoadingButton(true);

    const [email, password, response_msg] = document.querySelectorAll(
      "#email, #password, #response_msg"
    ) as unknown as [...HTMLFormElement[]];
    const response = (await newAxios("/login", "post", {
      email: email.value,
      password: password.value,
    })) as AvatarResponse;
    response_msg.textContent = "";
    response_msg.classList.remove("response_msg_success");
    setLoadingButton(false);
    if (response.status === 200 && response.data.success) {
      props.setUserAvatar(response.data.avatar);
      props.setUser((prevState: UserState) => ({
        ...prevState,
        isLogged: !prevState.isLogged,
        avatar: response.data.avatar,
      }));
    } else {
      response_msg.textContent = response.data.msg;
    }
  }
  return (
    <Box
      sx={{
        minHeight: "80vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Container component="main" maxWidth="sm">
          <Typography
            className="h1-titles"
            component="h1"
            variant="h5"
            sx={{ fontWeight: "bold" }}
          >
            {HU.forms.login.title}
          </Typography>
          <Divider className="form-divider" />
          <form onSubmit={userLogin}>
            <TextField
              className="form_input_field"
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              type="email"
              placeholder={HU.forms.login.emailFieldPlaceholder}
              autoComplete="email"
              autoFocus
              InputProps={{
                startAdornment: (
                  <IconButton disabled tabIndex={-1}>
                    <EmailIcon />
                  </IconButton>
                ),
              }}
            />
            <TextField
              className="form_input_field"
              margin="normal"
              required
              fullWidth
              name="password"
              id="password"
              type="password"
              placeholder={HU.forms.login.passwordFieldPlaceholder}
              autoComplete="new-password"
              InputProps={{
                autoComplete: "new-password",
                startAdornment: (
                  <div>
                    <IconButton disabled tabIndex={-1}>
                      <LockIcon />
                    </IconButton>
                    <Link
                      tabIndex={-1}
                      className="LogInForgotLink"
                      href="/forgot"
                    >
                      {HU.forms.login.forgotLink}
                    </Link>
                  </div>
                ),
              }}
            />
            <SubmitButton
              isLoading={loadingButton}
              buttonName={HU.forms.login.submitButton}
            />
            <div className="form_links"></div>
            <span>{HU.forms.login.signUpMessage} </span>
            <Link className="links" href="/register" variant="body2">
              {HU.forms.login.signUpLink}
            </Link>
          </form>
        </Container>
      </Box>
    </Box>
  );
}
