import { Grid, Pagination } from "@mui/material";

export default function WordsThemesPagination(props: { userSRS: any }) {
  function paginationHandler(e: React.ChangeEvent<unknown>, page: number) {
    props.userSRS.setCurrentPagiPage(page);
  }
  const getPagination = (props: any) => {
    return (
      <Grid item mt={3}>
        {Math.ceil(props.wordsCategories.data?.length! / props.itemPerPage) >
          1 && (
          <Pagination
            className="pagination"
            sx={{ float: "right" }}
            count={Math.ceil(
              props.wordsCategories.data?.length! / props.itemPerPage
            )}
            onChange={paginationHandler}
            page={props.currentPagiPage}
          />
        )}
      </Grid>
    );
  };
  return getPagination(props.userSRS);
}
