import { useState, useEffect } from "react";
import { useParams, Navigate, useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LockIcon from "@mui/icons-material/Lock";

import newAxios from "../../../axios/axios";
import SubmitButton from "../../../components/ui/submitButton";

import HU from "../../../languages/HU/hu";
import { NewPasswordResponse } from "../../../models/response/newPassword";
import { StatusResponse } from "../../../models/response/status";

export default function NewPassword() {
  const [loadingButton, setLoadingButton] = useState(false);
  const [isTokenValid, setTokenStatus] = useState<boolean | null>(null);
  const redirect = useNavigate();
  // get the tokenid from route params
  const { tokenid } = useParams();
  //Check token is valid or forgot
  const tokenCheck = async () => {
    const response = (await newAxios(
      "/forgot/" + tokenid,
      "get"
    )) as NewPasswordResponse;
    if (response.status === 200 && response.data.isValidToken) {
      setTokenStatus(true);
    } else if (response.status === 200 && !response.data.isValidToken) {
      setTokenStatus(false);
    } else if (response.status > 200) {
      setTokenStatus(false);
    }
  };

  useEffect(() => {
    tokenCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function sendNewPassword(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoadingButton(true);
    const password = document.getElementById("password") as HTMLInputElement;
    const password2 = document.getElementById("password2") as HTMLInputElement;
    const response_msg = document.getElementById("response_msg") as HTMLElement;
    const response = (await newAxios(`/newpassword/${tokenid}`, "post", {
      password: password.value,
      password2: password2.value,
    })) as StatusResponse;

    response_msg.textContent = "";
    response_msg.classList.remove("response_msg_success");
    setLoadingButton(false);
    if (response.status === 200 && response.data.success) {
      password.value = "";
      password2.value = "";
      response_msg.classList.add("response_msg_success");
      response_msg.textContent = response.data.msg;
      setTimeout(() => {
        redirect("/login");
      }, 3000);
    } else {
      response_msg.textContent = response.data.msg;
    }
  }
  // if token not valid redirrect to forgot route
  if (isTokenValid === false) {
    return <Navigate to="/forgot" />;
  } else if (isTokenValid === true) {
    return (
      <Box
        sx={{
          minHeight: "80vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Container component="main" maxWidth="sm">
            <Typography component="h1" variant="h5" sx={{ fontWeight: "bold" }}>
              {HU.forms.newPassword.title}
            </Typography>
            <Divider className="form-divider" />
            <form onSubmit={sendNewPassword}>
              <TextField
                className="form_input_field"
                margin="normal"
                required
                fullWidth
                name="password"
                id="password"
                type="password"
                placeholder={HU.forms.newPassword.passwordFieldPlaceholder}
                autoComplete="new-password"
                autoFocus
                InputProps={{
                  autoComplete: "new-password",
                  startAdornment: (
                    <IconButton disabled tabIndex={-1}>
                      <LockIcon />
                    </IconButton>
                  ),
                }}
              />
              <TextField
                className="form_input_field"
                margin="normal"
                required
                fullWidth
                name="password2"
                id="password2"
                type="password"
                placeholder={HU.forms.newPassword.password2FieldPlaceholder}
                autoComplete="new-password"
                InputProps={{
                  autoComplete: "new-password",
                  startAdornment: (
                    <IconButton disabled tabIndex={-1}>
                      <LockIcon />
                    </IconButton>
                  ),
                }}
              />
              <SubmitButton
                isLoading={loadingButton}
                buttonName={HU.forms.newPassword.submitButton}
              />
            </form>
          </Container>
        </Box>
      </Box>
    );
  } else {
    return null;
  }
}
