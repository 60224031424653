import { useState, useEffect } from "react";

import { Box, Grid, Typography, CircularProgress } from "@mui/material";
import newAxios from "../../../../../../axios/axios";
import { AxiosResponse } from "axios";

import { UserStatistics } from "../../../../../../models/response/afterLogin/userStatistics";

export default function UserStatistic() {
  const [userStatistic, setUserStatistic] = useState<UserStatistics>({
    data: {},
    isReady: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const getUserStatistics = async () => {
    try {
      const response = (await newAxios("/app/user/statistics", "GET")) as AxiosResponse;
      if (response.status === 200) {
        setUserStatistic({
          data: response.data,
          isReady: true,
        });
      }
    } catch (error) {
      console.error("Failed to fetch user data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  // calculate current study time
  function formatSeconds(seconds: number): string {
    const days = Math.floor(seconds / (24 * 60 * 60));
    const remainingSecondsAfterDays = seconds % (24 * 60 * 60);
    const hours = Math.floor(remainingSecondsAfterDays / (60 * 60));
    const remainingSecondsAfterHours = remainingSecondsAfterDays % (60 * 60);
    const minutes = Math.floor(remainingSecondsAfterHours / 60);
    const remainingSecondsAfterMinutes = remainingSecondsAfterHours % 60;

    const updatedDays = days > 0 ? `${days} nap ` : "";
    const updatedHours = hours > 0 ? `${hours} óra ` : "";
    const updatedMinutes = minutes > 0 ? `${minutes} perc ` : "";
    const updatedSeconds =
      remainingSecondsAfterMinutes > 0
        ? `${remainingSecondsAfterMinutes} másodperc`
        : "";

    return `${updatedDays}${updatedHours}${updatedMinutes}${updatedSeconds}`.trim();
  }

  // show user total study time
  const getUserTotalStudyTime = (): JSX.Element | undefined => {
    if (!userStatistic.isReady) {
      return;
    }
    if (userStatistic.data?.total_study_time === 0) {
      return (
        <Grid m={"auto"} mt={1} pl={1} xs={12} item>
          <Typography m={"2px auto"}>0 perc</Typography>
        </Grid>
      );
    } else {
      const totalTime = formatSeconds(
        userStatistic.data?.total_study_time as number
      );
      return (
        <Grid m={"auto"} mt={1} pl={1} xs={12} item>
          <Typography m={"2px auto"}>{totalTime}</Typography>
        </Grid>
      );
    }
  };

  useEffect(() => {
    if (!userStatistic.isReady) {
      getUserStatistics();
    }
  }, [userStatistic.isReady]); // Üres dependenciamaszk, hogy csak egyszer fusson le

  if (isLoading && !userStatistic.isReady) {
    return (
      <Grid container spacing={2}>
        <Grid textAlign={"center"} mt={5} item xs={12}>
          <CircularProgress size={"5rem"} />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Box className="indexLeftBoxContent">
        <Grid container sx={{ textAlign: "center" }}>
          <Typography m={"auto"} mt={1.5} sx={{ color: "gold" }}>
            - Tanulással töltött idő -
          </Typography>
          {getUserTotalStudyTime()}
        </Grid>
      </Box>
    );
  }
}
