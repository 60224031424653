import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";

function Copyright(): JSX.Element {
  return (
    <Typography variant="body2" className="copyright" align="center">
      {"Copyright © "}
      <Link className="links" href="http://localhost/">
        Beta app
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Footer(): JSX.Element {
  return (
    <Box
      className="mainFooter"
      sx={{
        position: "fixed",
        bottom: "0",
        width: "100%",
        minHeight: "10vh",
      }}
    >
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: "auto",
        }}
      >
        <Container maxWidth="sm">
          <Typography variant="body1" align="center">
            Beta app
          </Typography>
          <Copyright />
        </Container>
      </Box>
    </Box>
  );
}
