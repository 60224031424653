import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";

import SubmitButton from "../../../../components/ui/submitButton";
import newAxios from "../../../../axios/axios";
import Navigation from "./navigation";
import ProfilImage from "../../../../components/ui/profileImage";

import HU from "../../../../languages/HU/hu";
import { SetStateAction } from "react";
import { UserProfileResponse } from "../../../../models/response/userProfile";

export default function UserProfile(props: {
  userAvatar: string;
  setUserAvatar: React.Dispatch<SetStateAction<string>>;
}) {
  const [userData, setUserData] = useState<UserProfileResponse>({
    data: {},
    isReady: false,
  });
  const [dataChanged, setDataChanged] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [isEmailChanged, setIsEmailChanged] = useState(false);
  const redirect = useNavigate();

  const getUserProfileData = async () => {
    const response = (await newAxios(
      "/app/settings/profile",
      "GET"
    )) as UserProfileResponse;
    if (response.status === 200) {
      setUserData({
        data: response.data,
        isReady: true,
      });
    }
  };

  useEffect(() => {
    getUserProfileData();
  }, []);

  // check form data and if data is different set the button to active
  function checkChangedData() {
    const [username, email, password, response_msg] = document.querySelectorAll(
      "#username , #email, #password, #response_msg"
    ) as unknown as [...HTMLFormElement[]];
    if (response_msg.textContent !== "") {
      response_msg.textContent = "";
    }
    //if input value default and no password disable button
    if (
      userData.data !== undefined &&
      username.value !== userData.data.username &&
      password.value.length > 0
    ) {
      setDataChanged(true);
    } else if (
      userData.data !== undefined &&
      email.value !== userData.data.email &&
      password.value.length > 0
    ) {
      setDataChanged(true);
      setIsEmailChanged(true);
    } else {
      setDataChanged(false);
      setIsEmailChanged(false);
    }
  }

  async function sendNewProfileData(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoadingButton(true);
    const response_msg = document.getElementById("response_msg") as HTMLElement;
    const [username, email, password] = document.querySelectorAll(
      "#username , #email, #password"
    ) as unknown as [...HTMLFormElement[]];
    const response = (await newAxios("/app/settings/profile", "POST", {
      username: username.value,
      email: email.value,
      password: password.value,
    })) as UserProfileResponse;
    setLoadingButton(false);
    response_msg.classList.remove("response_msg_success");
    // if change was success and email changed (force userData to logout)
    if (
      response.data !== undefined &&
      response.status === 200 &&
      response.data.success &&
      response.data.wasEmailChange
    ) {
      response_msg.classList.add("response_msg_success");
      response_msg.textContent = response.data.msg as string;
      // set button to disabled
      setDataChanged(false);
      // update userData profile data with new
      setUserData((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          email: email.value,
          username: username.value,
        },
      }));
      password.value = "";
      setTimeout(() => {
        redirect("/app/logout");
      }, 5000);
    }
    // if only username changed
    else if (
      response.status === 200 &&
      response.data !== undefined &&
      response.data.success
    ) {
      response_msg.classList.add("response_msg_success");
      response_msg.textContent = response.data.msg as string;
      // set button to disabled
      setDataChanged(false);
      // update userData profile data with new
      setUserData((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          email: email.value,
          username: username.value,
        },
      }));
      password.value = "";
      // if data exists or wrong password etc
    } else {
      response_msg.textContent =
        response.data !== undefined ? (response.data.msg as string) : "";
    }
  }

  if (!userData.isReady) {
    return (
      <Box className="settings-content">
        <Navigation />
      </Box>
    );
  } else {
    return (
      <Box className="settings-content">
        <Navigation />
        <Container
          className="settings-content-container"
          component="main"
          maxWidth="sm"
        >
          <Typography mb={3} variant="h5" sx={{ fontWeight: "bold" }}>
            {HU.user_settings.profile.title}
            <Divider className="form-divider" />
          </Typography>
          {/* Profile Images and avatars */}
          <ProfilImage
            userAvatar={props.userAvatar}
            setUserAvatar={props.setUserAvatar}
          />
          <Grid mb={3} container spacing={1}>
            <Grid item xs={6} textAlign="left">
              {HU.user_settings.profile.registeredMessage}
            </Grid>
            <Grid item xs={6} textAlign="right">
              {userData.data !== undefined && userData.data.registered}
            </Grid>
          </Grid>
          <Divider className="form-divider" />
          <Typography my={2} variant="h6" sx={{ fontWeight: "bold" }}>
            {HU.user_settings.profile.fieldsTitle}
          </Typography>
          {isEmailChanged && (
            <Typography m={1} sx={{ fontWeight: "bold" }} textAlign="left">
              {HU.user_settings.profile.preUpdateMessage}
            </Typography>
          )}
          <form onSubmit={sendNewProfileData}>
            <TextField
              className="form_input_field"
              onChange={checkChangedData}
              margin="normal"
              required
              fullWidth
              id="username"
              name="username"
              type="text"
              placeholder={HU.user_settings.profile.usernameFieldPlaceholder}
              defaultValue={
                userData.data !== undefined && userData.data.username
              }
              InputProps={{
                startAdornment: (
                  <IconButton disabled tabIndex={-1}>
                    <InsertEmoticonIcon />
                  </IconButton>
                ),
              }}
            />
            <TextField
              className="form_input_field"
              onChange={checkChangedData}
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              type="email"
              placeholder={HU.user_settings.profile.emailFieldPlaceholder}
              autoComplete="email"
              defaultValue={userData.data !== undefined && userData.data.email}
              InputProps={{
                startAdornment: (
                  <IconButton disabled tabIndex={-1}>
                    <EmailIcon />
                  </IconButton>
                ),
              }}
            />
            <TextField
              className="form_input_field"
              onChange={checkChangedData}
              margin="normal"
              required
              fullWidth
              name="password"
              id="password"
              type="password"
              placeholder={HU.user_settings.profile.passwordFieldPlaceholder}
              autoComplete="new-password"
              InputProps={{
                autoComplete: "new-password",
                startAdornment: (
                  <div>
                    <IconButton disabled tabIndex={-1}>
                      <LockIcon />
                    </IconButton>
                  </div>
                ),
              }}
            />
            <SubmitButton
              isLoading={loadingButton}
              buttonName={HU.user_settings.profile.submitButton}
              dataChanged={dataChanged}
            />
          </form>
        </Container>
      </Box>
    );
  }
}
