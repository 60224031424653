import { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import { Divider } from "@mui/material/";

import SubmitButton from "../../../../components/ui/submitButton";
import newAxios from "../../../../axios/axios";
import Navigation from "./navigation";

import HU from "../../../../languages/HU/hu";
import {
  UserSettingsResponse,
  MirrorUserData,
} from "../../../../models/response/userSettings";

export default function UserSettings() {
  const [user, setUser] = useState<UserSettingsResponse>({
    data: {},
    isReady: false,
  });
  const [prev_settings, set_prev_settings] = useState<MirrorUserData>();
  const [loadingButton, setLoadingButton] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);

  const getUserAccountData = async () => {
    const response = (await newAxios(
      "/app/settings/account",
      "GET"
    )) as UserSettingsResponse;
    if (response.status === 200) {
      setUser({
        data: response.data,
        isReady: true,
      });
      set_prev_settings(response.data);
    }
  };
  const sendNewAccountSettings = async function (
    e: React.FormEvent<HTMLFormElement>
  ) {
    e.preventDefault();
    setLoadingButton(true);
    const [
      email_homework,
      response_msg,
    ] = document.querySelectorAll(
      "#email_homework, #response_msg"
    ) as unknown as [...HTMLFormElement[]];
    const response = (await newAxios("/app/settings/account", "POST", {
      email_homework: email_homework.checked
    })) as UserSettingsResponse;
    setLoadingButton(false);
    response_msg.classList.remove("response_msg_success");
    if (
      response.status === 200 &&
      response.data !== undefined &&
      response.data.success
    ) {
      response_msg.classList.add("response_msg_success");
      response_msg.textContent = response.data.msg as string;
      // set button to disabled
      setDataChanged(false);
      // update with new settings
      setUser((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          email_homework: email_homework.checked,
        },
      }));
      // update comparative settings object with new settings (for button active status)
      set_prev_settings((prevState) => ({
        ...prevState,
        email_homework: email_homework.checked,
      }));
    } else {
      response_msg.textContent =
        response.data !== undefined ? (response.data.msg as string) : "";
    }
  };

  useEffect(() => {
    getUserAccountData();
  }, []);

  const checkOptions = function (e: any, checked: boolean) {
    const [email_homework] =
      document.querySelectorAll(
        "#email_homework"
      ) as unknown as [...HTMLFormElement[]];
    setUser((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [e.target.name.replaceAll("'", "")]: checked,
      },
    }));
    if (
      prev_settings !== undefined &&
      (email_homework.checked !== prev_settings.email_homework)
    ) {
      // set button to disabled
      setDataChanged(true);
    } else {
      setDataChanged(false);
    }
  };

  if (!user.isReady) {
    return (
      <Box className="settings-content">
        <Navigation />
      </Box>
    );
  } else {
    return (
      <Box className="settings-content">
        <Navigation />
        <Container
          className="settings-content-container"
          component="main"
          maxWidth="sm"
        >
          <Typography mb={3} variant="h5" sx={{ fontWeight: "bold" }}>
            {HU.user_settings.account.email_settings_title}
            <Divider className="form-divider" />
          </Typography>
          <form onSubmit={sendNewAccountSettings}>
            <Grid mb={3} container spacing={1}>
              <Grid item xs={6} textAlign="left">
                <Typography variant="h6">
                  {HU.user_settings.account.options_email_homework}
                </Typography>
              </Grid>
              <Grid item xs={6} textAlign="right">
                <Switch
                  className="options_switch"
                  id={"email_homework"}
                  name="email_homework"
                  onChange={checkOptions}
                  checked={user.data! && user.data.email_homework}
                  classes={{
                    track:
                      user.data! && user.data.email_homework
                        ? "switch_active"
                        : "",
                  }}
                />
              </Grid>
            </Grid>
            <SubmitButton
              isLoading={loadingButton}
              buttonName={HU.user_settings.account.submitButton}
              dataChanged={dataChanged}
            />
          </form>
        </Container>
      </Box>
    );
  }
}
