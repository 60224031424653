import { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { TextField, Button, Divider } from "@mui/material/";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/material/styles";

import SubmitButton from "../../../../components/ui/submitButton";
import newAxios from "../../../../axios/axios";
import Navigation from "./navigation";

import HU from "../../../../languages/HU/hu";
import { UserSupportResponse } from "../../../../models/response/userSupport";
import { StatusResponse } from "../../../../models/response/status";

export default function UserSupport() {
  const [dataChanged, setDataChanged] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [data, setData] = useState<UserSupportResponse>({
    rows: [],
    isReady: false,
  });
  const [currentPagiPage, setCurrentPagiPage] = useState(1);
  const [newTicketDataLength, setNewTicketDataLength] = useState({
    subject: 0,
    message: 0,
  });
  const itemPerPage = 5;

  const getUserTickets = async () => {
    const response = (await newAxios(
      "/app/settings/mytickets",
      "GET"
    )) as UserSupportResponse;
    if (response.data !== undefined && response.status === 200) {
      let userInvitations = response.data.map((user, index) => {
        return createData(
          user.created_date,
          user.subject,
          ticketStatus(user.status),
          <Button
            href={"/app/settings/support/tickets/" + user.unique_token}
            className="form-button"
            size="small"
            variant="contained"
            color="inherit"
          >
            Megtekint
          </Button>
        );
      });
      setData({
        rows: userInvitations,
        isReady: true,
      });
    }
  };

  useEffect(() => {
    getUserTickets();
  }, [loadData]);

  // Send new ticket to server
  async function sendNewTicket(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoadingButton(true);
    const [subject, message, response_msg] = document.querySelectorAll(
      "#subject, #message, #response_msg"
    ) as unknown as [...HTMLFormElement[]];
    const response = (await newAxios("/app/settings/newticket", "POST", {
      subject: subject.value,
      message: message.value,
    })) as StatusResponse;
    setLoadingButton(false);
    response_msg.classList.remove("response_msg_success");
    if (response.status === 200 && response.data.success) {
      response_msg.classList.add("response_msg_success");
      response_msg.textContent = response.data.msg;
      // set button to disabled
      setDataChanged(false);
      subject.value = "";
      message.value = "";
      setLoadData(!loadData);
      setNewTicketDataLength({
        subject: 0,
        message: 0,
      });
    } else {
      response_msg.textContent = response.data.msg;
    }
  }

  // set the button to active if fields are not empty
  function checkFields() {
    const [subject, message, response_msg] = document.querySelectorAll(
      "#subject, #message, #response_msg"
    ) as unknown as [...HTMLFormElement[]];
    if (response_msg.textContent !== "") {
      response_msg.textContent = "";
    }
    //if input value default and no password disable button
    if (subject.value.length > 0 || message.value.length > 0) {
      setNewTicketDataLength({
        subject: subject.value.length,
        message: message.value.length,
      });
      if (subject.value.length > 0 && message.value.length > 0) {
        setDataChanged(true);
      } else {
        setDataChanged(false);
      }
    } else {
      setNewTicketDataLength({
        subject: 0,
        message: 0,
      });
    }
  }

  function paginationHandler(e: React.ChangeEvent<unknown>, page: number) {
    setCurrentPagiPage(page);
  }
  if (!data.isReady) {
    return (
      <Box className="settings-content">
        <Navigation />
      </Box>
    );
  } else {
    return (
      <Box className="settings-content">
        <Navigation />
        <Container
          className="settings-content-container"
          component="main"
          maxWidth="sm"
        >
          <Typography mb={3} variant="h5" sx={{ fontWeight: "bold" }}>
            {HU.user_settings.support.title}
            <Divider className="form-divider" />
          </Typography>
          <form onSubmit={sendNewTicket}>
            <TextField
              className="form_input_field"
              onChange={checkFields}
              autoFocus
              margin="normal"
              fullWidth
              name="subject"
              id="subject"
              type="text"
              placeholder={HU.user_settings.support.subjectFieldPlaceholder}
              InputProps={{
                startAdornment: (
                  <Typography tabIndex={-1} className="ticketFormEndLine">
                    {newTicketDataLength.subject > 0 &&
                      newTicketDataLength.subject + " / 50"}
                  </Typography>
                ),
              }}
              required
            />
            <TextField
              className="form_input_field"
              onChange={checkFields}
              margin="normal"
              fullWidth
              multiline
              rows={12}
              name="message"
              id="message"
              type="text"
              placeholder={HU.user_settings.support.messageFieldPlaceholder}
              InputProps={{
                startAdornment: (
                  <Typography
                    m={1}
                    tabIndex={-1}
                    className="ticketFormEndLineMessage"
                  >
                    {newTicketDataLength.message > 0 &&
                      newTicketDataLength.message + " / 1000"}
                  </Typography>
                ),
              }}
              required
            />
            <SubmitButton
              isLoading={loadingButton}
              buttonName={HU.user_settings.support.submitButton}
              dataChanged={dataChanged}
            />
          </form>
        </Container>
        <Container
          sx={{ marginBottom: 5, padding: "1rem 0.5rem" }}
          maxWidth="md"
        >
          <Typography mb={2} variant="h6" sx={{textDecoration: "underline"}}>
            {HU.user_settings.support.myTicketTitle}
          </Typography>
          {/* if more invited users than 5 show pagination */}
          {Math.ceil(
            data.rows !== undefined ? data.rows.length / itemPerPage : 0
          ) > 1 && (
            <Pagination
              className="pagination"
              sx={{float: "right"}}
              count={Math.ceil(
                data.rows !== undefined ? data.rows.length / itemPerPage : 0
              )}
              onChange={paginationHandler}
            />
          )}
          <TableContainer className="table_container" component={Paper}>
            <Table >
              <TableHead>
                <TableRow className="table_header">
                  <StyledTableCell>
                    {HU.user_settings.support.ticketCreated}
                  </StyledTableCell>
                  <StyledTableCell>
                    {HU.user_settings.support.ticketSubject}
                  </StyledTableCell>
                  <StyledTableCell>
                    {HU.user_settings.support.ticketStatus}
                  </StyledTableCell>
                  <StyledTableCell>
                    {HU.user_settings.support.ticketHandler}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Invited, Email, Registered, Subscription, Reward */}
                {data.rows !== undefined &&
                  data.rows
                    .slice(
                      currentPagiPage * itemPerPage - itemPerPage,
                      currentPagiPage * itemPerPage
                    )
                    .map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          className="table_cell"
                        >
                          {row.Created}
                        </StyledTableCell>
                        <StyledTableCell className="table_cell">
                          {row.Subject}
                        </StyledTableCell>
                        <StyledTableCell
                          className={getStatusClassName(row.Status)}
                        >
                          {row.Status}
                        </StyledTableCell>
                        <StyledTableCell className="table_cell"
                        >
                          {row.Button}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Box>
    );
  }
}

function createData(
  Created: string,
  Subject: string,
  Status: string,
  Button: JSX.Element
) {
  return { Created, Subject, Status, Button };
}

function ticketStatus(status: number) {
  if (status === 0) {
    return HU.user_settings.support.ticketStatusNull;
  } else if (status === 1) {
    return HU.user_settings.support.ticketStatusOne;
  } else {
    return HU.user_settings.support.ticketStatusTwo;
  }
}

function getStatusClassName(status: string) {
  if (status === HU.user_settings.support.ticketStatusNull) {
    return "ticket_status_zero";
  } else if (status === HU.user_settings.support.ticketStatusOne) {
    return "ticket_status_one";
  } else if (status === HU.user_settings.support.ticketStatusTwo) {
    return "ticket_status_two";
  }
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
