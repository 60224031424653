import { useState } from "react";
import { Box, Container, Typography, TextField, Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import newAxios from "../../../../axios/axios";
import SubmitButton from "../../../../components/ui/submitButton";

import HU from "../../../../languages/HU/hu";
import Navigation from "./navigation";

import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatSizeIcon from "@mui/icons-material/FormatSize";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import CallToActionIcon from "@mui/icons-material/CallToAction";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import FormatShapesIcon from "@mui/icons-material/FormatShapes";

import { SelectChangeEvent } from "@mui/material/";
import { StatusResponse } from "../../../../models/response/status";

// its for only preview, wont send it
import EmailStructure from "./emailStructure";

export default function SendMessages() {
  const [selectValue, setSelectValue] = useState("one");
  const [loadingButton, setLoadingButton] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const [emailMessageData, setEmailMessageData] = useState(EmailStructure(""));
  const [newTicketDataLength, setNewTicketDataLength] = useState({
    subject: 0,
    message: 0,
  });
  const [selectedTextAreaCharacter, setSelectedTextAreaCharacter] = useState(0);

  function updateSelectValue(e: SelectChangeEvent<string>) {
    setSelectValue(e.target.value);
  }

  function formatIcons(start: string, end: string, name: string) {
    const message = document.getElementById("message") as HTMLFormElement;

    function insertStringAtPosition(
      originalString: string,
      insertString: string,
      position: number
    ) {
      if (position >= 0 && position <= originalString.length) {
        return (
          originalString.slice(0, position) +
          insertString +
          originalString.slice(position)
        );
      }
      // Ha a pozíció nem érvényes, visszatérünk az eredeti stringgel
      return originalString;
    }

    const newFormat = `${start} ${name} ${end}`;
    const result = insertStringAtPosition(
      message.value,
      newFormat,
      selectedTextAreaCharacter
    );
    message.value = result;
    setEmailMessageData(EmailStructure(result));
  }

  async function messageHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoadingButton(true);
    const username = document.getElementById("username") as HTMLFormElement;
    const subject = document.getElementById("subject") as HTMLFormElement;
    const message = document.getElementById("message") as HTMLFormElement;
    const response_msg = document.getElementById("response_msg") as HTMLElement;
    const response = (await newAxios("/app/support/message/send", "POST", {
      subject: subject.value,
      message: "<div class='custom-content'>" + message.value + "</div>",
      target: selectValue,
      username: username !== null ? username.value : "notOne",
    })) as StatusResponse;
    setLoadingButton(false);
    response_msg.classList.remove("response_msg_success");
    if (response.status === 200 && response.data.success) {
      response_msg.classList.add("response_msg_success");
      response_msg.textContent = response.data.msg;
      subject.value = "";
      message.value = "";
      setEmailMessageData(EmailStructure(""));
      setNewTicketDataLength({
        subject: 0,
        message: 0,
      });
      if (selectValue === "one") {
        username.value = "";
      }
    } else {
      response_msg.textContent = response.data.msg;
    }
  }
  // we find the current clicked character position before insert new format
  const checkSelectionLine = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const target = e.target as HTMLElement;
    if (
      target.tagName.toLowerCase() === "textarea" ||
      target.tagName.toLowerCase() === "input"
    ) {
      const inputElement = target as HTMLTextAreaElement | HTMLInputElement;
      setSelectedTextAreaCharacter(inputElement.selectionEnd as number);
    }
  };
  // we find the current entered character position before insert new format
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      const target = e.target as HTMLElement;
      if (
        target.tagName.toLowerCase() === "textarea" ||
        target.tagName.toLowerCase() === "input"
      ) {
        const inputElement = target as HTMLTextAreaElement | HTMLInputElement;
        setSelectedTextAreaCharacter(inputElement.selectionEnd as number);
      }
    }
  };

  // set the button to active if fields are not empty
  function checkFields() {
    const username = document.getElementById("username") as HTMLFormElement;
    const subject = document.getElementById("subject") as HTMLFormElement;
    const message = document.getElementById("message") as HTMLFormElement;
    const response_msg = document.getElementById("response_msg") as HTMLElement;
    if (response_msg.textContent !== "") {
      response_msg.textContent = "";
    }
    //if input value default and no password disable button
    if (subject.value.length > 0 || message.value.length > 0) {
      setNewTicketDataLength({
        subject: subject.value.length,
        message: message.value.length,
      });
      setEmailMessageData(EmailStructure(message.value));
      if (
        selectValue === "one" &&
        subject.value.length > 0 &&
        message.value.length > 0 &&
        username.value.length > 0
      ) {
        setDataChanged(true);
      } else if (
        selectValue !== "one" &&
        subject.value.length > 0 &&
        message.value.length > 0
      ) {
        setDataChanged(true);
      } else {
        setDataChanged(false);
      }
    } else {
      setNewTicketDataLength({
        subject: 0,
        message: 0,
      });
    }
  }
  return (
    <Box className="settings-content">
      <Navigation />
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Container sx={{ padding: "1rem 0.5rem" }} maxWidth="sm">
            <Typography mb={3} variant="h5" sx={{ fontWeight: "bold" }}>
              {HU.support_settings.sendMessages.messageTitle}
            </Typography>
            <FormControl className="support_messages_selector">
              <Select
                sx={{ padding: 1 }}
                value={selectValue}
                onChange={updateSelectValue}
                autoWidth
              >
                <MenuItem value={"one"}>
                  {HU.support_settings.sendMessages.menuOne}
                </MenuItem>
                <MenuItem value={"subs"}>
                  {HU.support_settings.sendMessages.menuSubs}
                </MenuItem>
                <MenuItem value={"all"}>
                  {HU.support_settings.sendMessages.menuAll}
                </MenuItem>
              </Select>
            </FormControl>
            <form onSubmit={messageHandler}>
              {selectValue === "one" && (
                <TextField
                  onChange={checkFields}
                  className="form_input_field"
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  name="username"
                  type="text"
                  placeholder={HU.support_settings.sendMessages.usernameField}
                />
              )}
              <TextField
                onChange={checkFields}
                className="form_input_field"
                margin="normal"
                required
                fullWidth
                id="subject"
                name="subject"
                type="text"
                placeholder={HU.support_settings.sendMessages.subjectField}
                InputProps={{
                  startAdornment: (
                    <Typography tabIndex={-1} className="ticketFormEndLine">
                      {newTicketDataLength.subject > 0 &&
                        newTicketDataLength.subject + " / 50"}
                    </Typography>
                  ),
                }}
              />
              <div style={{ marginTop: 5, marginLeft: 5, float: "left" }}>
                <FormatAlignLeftIcon
                  onClick={(e) =>
                    formatIcons(
                      "<p style='text-align: left'>",
                      "</p>",
                      "balra igazítás"
                    )
                  }
                  fontSize="large"
                  style={{ color: "white", cursor: "pointer" }}
                ></FormatAlignLeftIcon>
                <FormatAlignCenterIcon
                  onClick={(e) =>
                    formatIcons(
                      "<p style='text-align: center'>",
                      "</p>",
                      "középre igazítás"
                    )
                  }
                  fontSize="large"
                  style={{ color: "white", cursor: "pointer" }}
                ></FormatAlignCenterIcon>
                <FormatAlignRightIcon
                  onClick={(e) =>
                    formatIcons(
                      "<p style='text-align: right'>",
                      "</p>",
                      "Jobbra igazítás"
                    )
                  }
                  fontSize="large"
                  style={{ color: "white", cursor: "pointer" }}
                ></FormatAlignRightIcon>
                <FormatBoldIcon
                  onClick={(e) => formatIcons("<b>", "</b>", "kövér betű")}
                  fontSize="large"
                  style={{ color: "white", cursor: "pointer" }}
                ></FormatBoldIcon>
                <FormatItalicIcon
                  onClick={(e) => formatIcons("<i>", "</i>", "dölt betű")}
                  fontSize="large"
                  style={{ color: "white", cursor: "pointer" }}
                ></FormatItalicIcon>
                <FormatColorTextIcon
                  onClick={(e) => formatIcons("<u>", "</u>", "aláhúzott")}
                  fontSize="large"
                  style={{ color: "white", cursor: "pointer" }}
                ></FormatColorTextIcon>
                <ModeEditOutlineIcon
                  onClick={(e) => formatIcons("<p>", "</p>", "paragrafus")}
                  fontSize="large"
                  style={{ color: "white", cursor: "pointer" }}
                ></ModeEditOutlineIcon>
                <FormatSizeIcon
                  onClick={(e) =>
                    formatIcons("<font size='1'>", "</font>", "betüméret")
                  }
                  fontSize="large"
                  style={{ color: "white", cursor: "pointer" }}
                ></FormatSizeIcon>
                <FormatListBulletedIcon
                  onClick={(e) =>
                    formatIcons("<ul><li>", "</li></ul>", "List típus")
                  }
                  fontSize="large"
                  style={{ color: "white", cursor: "pointer" }}
                ></FormatListBulletedIcon>
                <FormatListNumberedIcon
                  onClick={(e) =>
                    formatIcons("<ol><li>", "</li></ol>", "List típus")
                  }
                  fontSize="large"
                  style={{ color: "white", cursor: "pointer" }}
                ></FormatListNumberedIcon>
                <HorizontalRuleIcon
                  onClick={(e) =>
                    formatIcons("<div class='separator'>", "</div>", "")
                  }
                  fontSize="large"
                  style={{ color: "white", cursor: "pointer" }}
                ></HorizontalRuleIcon>
                <FormatShapesIcon
                  onClick={(e) =>
                    formatIcons("<p><a href='#''>", "</a></p>", "ez a link")
                  }
                  fontSize="large"
                  style={{ color: "white", cursor: "pointer" }}
                ></FormatShapesIcon>
                <CallToActionIcon
                  onClick={(e) => formatIcons("", "<br />", "")}
                  fontSize="large"
                  style={{ color: "white", cursor: "pointer" }}
                ></CallToActionIcon>
              </div>
              <TextField
                onChange={checkFields}
                onClick={checkSelectionLine}
                onKeyUp={handleKeyDown}
                className="form_input_field"
                margin="normal"
                fullWidth
                multiline
                rows={15}
                name="message"
                id="message"
                type="text"
                placeholder={HU.support_settings.sendMessages.messageField}
                sx={{ margin: "0px 0px 5px 0px", padding: 0 }}
                InputProps={{
                  startAdornment: (
                    <Typography
                      m={1}
                      tabIndex={-1}
                      className="ticketFormEndLineMessage"
                    >
                      {newTicketDataLength.message > 0 &&
                        newTicketDataLength.message + " / 1000"}
                    </Typography>
                  ),
                }}
                required
              />
              <SubmitButton
                isLoading={loadingButton}
                buttonName={HU.user_settings.support.submitButton}
                dataChanged={dataChanged}
              />
            </form>
          </Container>
        </Grid>
        <Grid item md={6}>
          <Typography
            mb={3}
            variant="h5"
            sx={{ fontWeight: "bold", padding: "1rem 0.5rem" }}
          >
            {HU.support_settings.sendMessages.preview}
          </Typography>
          <Container>
            <div dangerouslySetInnerHTML={{ __html: emailMessageData }}></div>
          </Container>
        </Grid>
      </Grid>
    </Box>
  );
}
