import { Grid, Typography } from "@mui/material";
import BorderLinearProgress from "../progressBar";

const getProgressPercent = (
  userDiscoveredWords: number,
  wordsCategoryTotalWords: number
) => {
  const catTotalWords =
    wordsCategoryTotalWords === null ? 0 : wordsCategoryTotalWords;
  const userCatWords = userDiscoveredWords === null ? 0 : userDiscoveredWords;
  if (userCatWords === 0 && catTotalWords === 0) {
    return 0;
  } else {
    const currentPercent = (userCatWords / catTotalWords) * 100;
    return currentPercent;
  }
};

const getUserDiscoveredWordsInfo = (props: any) => {
  return (
    <Grid container spacing={2}>
      <Grid item sm={6} xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              textAlign={"center"}
              className="index_rightbox_wordsTitle_beginner"
              sx={{ textDecoration: "underline!important" }}
            >
              Felfedezett kezdő szavak
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography mx={1} className="index_rightbox_wordsTitle_beginner">
              {props.userWordsStatus.userDiscoveredWords}
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign={"right"}>
            <Typography mx={1} className="index_rightbox_wordsTitle_beginner">
              {props.userWordsStatus.totalCategoryWords}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <BorderLinearProgress
              variant="determinate"
              value={getProgressPercent(
                props.userWordsStatus.userDiscoveredWords,
                props.userWordsStatus.totalCategoryWords
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={6} xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              textAlign={"center"}
              className="index_rightbox_wordsTitle_beginner"
              sx={{ textDecoration: "underline!important" }}
            >
              Felfedezett haladó szavak
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography mx={1} className="index_rightbox_wordsTitle_beginner">
              {props.userWordsStatus.userDiscoveredAdditionalWords}
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign={"right"}>
            <Typography mx={1} className="index_rightbox_wordsTitle_beginner">
              {props.userWordsStatus.totalAdditionalCategoryWords}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <BorderLinearProgress
              variant="determinate"
              value={getProgressPercent(
                props.userWordsStatus.userDiscoveredAdditionalWords,
                props.userWordsStatus.totalAdditionalCategoryWords
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default function DiscoveredWordsInfoElements(props: { userSRS: any }) {
  return getUserDiscoveredWordsInfo(props.userSRS);
}
