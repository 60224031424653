const HU = {
    forms: {
        login: {
            title: "Bejelentkezés",
            emailFieldPlaceholder: "E-mail cím",
            passwordFieldPlaceholder: "Jelszó",
            submitButton: "Bejelentkezés",
            forgotLink: "Elfelejtetted?",
            signUpLink: "Regisztráció",
            signUpMessage: "Nincs még felhasználód?"
        },
        forgot: {
            title: "Elfelejtettem a jelszavam",
            infoMsg: "E-mailen elküldjük neked, hogyan állítsd vissza a jelszavad.",
            emailFieldPlaceholder: "E-mail cím",
            submitButton: "Küldés",
            signInMessage: "vagy ",
            signInLink: "Bejelentkezés",
            signUpMessage: "Nincs még felhasználód?",
            signUpLink: "Regisztráció",
        },
        register: {
            title: "Regisztráció",
            usernameFieldPlaceholder: "Felhasználónév",
            emailFieldPlaceholder: "E-mail cím",
            passwordFieldPlaceholder: "Jelszó",
            password2FieldPlaceholder: "Jelszó újra",
            submitButton: "Regisztráció",
            signInMessage: "Van már felhasználód? ",
            signInLink: "Bejelentkezés",
        },
        newPassword: {
            title: "Jelszó módosítása",
            passwordFieldPlaceholder: "Új Jelszó",
            password2FieldPlaceholder: "Új Jelszó újra",
            submitButton: "Mentés",
        },
        invitation: {
            title: "meghívó linkje",
            usernameFieldPlaceholder: "Felhasználónév",
            emailFieldPlaceholder: "E-mail cím",
            passwordFieldPlaceholder: "Jelszó",
            password2FieldPlaceholder: "Jelszó újra",
            submitButton: "Regisztráció",
        }
    },
    user_settings: {
        profile: {
            title: "Profil adatok",
            fieldsTitle: "Felhasználónév és/vagy E-mail cím módosítása",
            registeredMessage: "Csatlakozás időpontja:",
            emailStatusMessage: "Email állapota:",
            activeThemeMessage: "Aktív téma:",
            usernameFieldPlaceholder: "Felhasználónév",
            emailFieldPlaceholder: "E-mail cím",
            passwordFieldPlaceholder: "Jelenlegi jelszó",
            submitButton: "Mentés",
            preUpdateMessage: "Sikeres E-mail cím módosítása után kijelentkeztetünk és újra kell aktíválnod fiókodat az új e-mail címedre kapott linkel."
        },
        subscription: {
            noActivetitle: "Jelenleg nincs aktív előfizetésed",
            noActiveSubtitle: "Válassz a csomagok közül",
            whyWorth: "Milyen előnyökkel jár az előfizetés?",
            Activetitle: "Előfizetés állapota: Aktív",
            nextButton: "Tovább"
        },
        password: {
            title: "Jelszó módosítása",
            currentFieldPlaceholder: "Jelenlegi Jelszó",
            passwordFieldPlaceholder: "Új Jelszó",
            password2FieldPlaceholder: "Új Jelszó újra",
            submitButton: "Mentés",
        },
        invite: {
            title: "Meghívó küldése",
            infoText: "A meghívó visszavonható amíg még nem regisztrált a meghívott",
            ownLinkTitle: "Vagy regisztráljon saját meghívó linkeden keresztül",
            invitedUsersTitle: "Meghívottak",
            rewardMsg: "+5000 Kredit jóváírva",
            emailFieldPlaceholder: "E-mail címe",
            copy: "Másolás",
            copied: "Kimásolva!",
            submitButton: "Küldés",
            table: {
                status: "Státusz",
                invitedTitle: "Elküldve",
                emailTitle: "E-mail cím",
                registeredTitle: "Regisztrált",
                subscriptionTitle: "Előfizetett",
                rewardTitle: "Jutalom",
                yes: "Igen",
                no: "Nem"
            },
            rewardButtons: {
                notYet: "Még nincs",
                collectible: "Begyüjthető!",
                collected: "Begyüjtve!"
            },
            cancelInvitation: {
                confirmTitle: "Biztosan törölni szeretnéd ezt a meghívót?",
                confirmDetails: "A törlés után jóváírjuk a meghívót",
                confirmYes: "Igen",
                confirmNo: "Nem",
            }
        },
        account: {
            email_settings_title: "Email beállítások",
            options_email_homework: "Lecke küldése",
            submitButton: "Mentés",
        },
        support: {
            title: "Üzenet küldése az ügyfélszolgálatnak",
            subjectFieldPlaceholder: "Tárgy...",
            messageFieldPlaceholder: "Üzenet...",
            submitButton: "Küldés",
            myTicketTitle: "Üzenetek",
            ticketCreated: "Elküldve",
            ticketSubject: "Tárgy",
            ticketStatus: "Státusz",
            ticketHandler: "Üzenetek kezelése",
            ticketStatusNull: "Válaszra vár",
            ticketStatusOne: "Válasz érkezett!",
            ticketStatusTwo: "Lezárva!",
            openedTicket: {
                submitButton: "Válasz",
                closeButton: "Téma lezárása",
                closeButtonDisabled: "Téma lezárva",
                confirmTitle: "Biztosan lezárod a témát?",
                confirmDetails: "Zárolás után már nem tudsz hozzászolni ehhez a témához.",
                confirmYes: "Igen",
                confirmNo: "Nem",
                messageFieldPlaceholder: "Üzenet...",
                replyTitle: "Válasz küldése"
            }
        },

    },
    support_settings: {
        messages: {
            myTicketTitle: "Felhasználói üzenetek",
            ticketCreated: "Elküldve",
            ticketSubject: "Tárgy",
            ticketStatus: "Státusz",
            ticketHandler: "Üzenetek kezelése",
            ticketStatusNull: "Válaszra vár",
            ticketStatusOne: "Válasz érkezett!",
            ticketStatusTwo: "Lezárva!",
        },
        rewards: {
            creditsTitle: "Kreditek küldése",
            menuOne: "Egy kiválasztott felhasználónak",
            menuSubs: "Csak az előfizetőknek",
            menuAll: "Mindenkinek",
            usernameField: "Felhasználóneve",
            valueField: "Kredit mennyiség",
            submitButton: "Küldés"
        },
        sendMessages: {
            messageTitle: "Üzenet küldése",
            menuOne: "Egy kiválasztott felhasználónak",
            menuSubs: "Csak az előfizetőknek",
            menuAll: "Mindenkinek",
            usernameField: "Felhasználóneve",
            subjectField: "Üzenet tárgya",
            messageField: "Üzenet...",
            submitButton: "Küldés",
            preview: "E-mail előlnézete",
        },
    },
    protectedHeader: {
        userSettingsMenuTitle: "Beállítások"
    }
}

export default HU;