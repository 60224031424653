import axios from "axios";

const newAxios = (url: string, method: string, body?: object) => {
  return new Promise(async (resolve, reject) => {
    const serverProxy = "/api/";
    axios.defaults.withCredentials = true;
    try {
      const response = await axios({
        method: method,
        url: serverProxy + url,
        data: body,
      });
      resolve(response);
    } catch (err) {
      if (err instanceof Error) {
        console.log(err.message);
      } else {
        console.log("Unexpected error", err);
      }
    }
  });
};

export default newAxios;
