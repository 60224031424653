import { useState, useEffect } from "react";
import EmailIcon from "@mui/icons-material/Email";
import MailIcon from "@mui/icons-material/Mail";
import {
  Grid,
  Badge,
  Box,
  Avatar,
  Link,
  Typography,
  Divider,
  CircularProgress,
  Button,
} from "@mui/material";
import BorderLinearProgress from "../../../../../../components/ui/progressBar";
import { indexUserData } from "../../../../../../models/response/afterLogin/indexUserData";
import newAxios from "../../../../../../axios/axios";

import Links from "../../../../../../languages/HU/links";
import UserHomeworkStatus from "./userHomeworkStatus";
const leftBoxIndexIconLinks = Links.indexPageLeftBoxIcons;

export default function UserInfo() {
  const [user, setUser] = useState<indexUserData>({ data: {}, isReady: false });
  const [isLoading, setIsLoading] = useState(true);
  const getUserData = async () => {
    try {
      const response = (await newAxios("/app/", "GET")) as indexUserData;
      if (response.status === 200) {
        setUser({
          data: response.data,
          isReady: true,
        });
      }
    } catch (error) {
      console.error("Failed to fetch user data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCurrentProgressPercent = () => {
    const hundredPercent = user.data?.exp_end! - user.data?.exp_start!;
    const current = user.data?.exp_end! - user.data?.exp!;
    const actualState = hundredPercent - current;
    const percent = (actualState / hundredPercent) * 100;
    return Math.round(percent as number);
  };

  useEffect(() => {
    if (!user.isReady) {
      getUserData();
    }
  }, [user.isReady]); // Üres dependenciamaszk, hogy csak egyszer fusson le

  if (isLoading && !user.isReady) {
    return (
      <Grid container spacing={2}>
        <Grid textAlign={"center"} mt={5} item xs={12}>
          <CircularProgress size={"5rem"} />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Box className="indexLeftBoxContent">
        <Box pt={0.1}>
          <Link href="/app/settings/profile">
            <Avatar
              className="indexProfileAvatar"
              alt="profile-img"
              src={user.data?.avatar}
              title="Profilkép módosítása"
            />
          </Link>
          <Typography mb={1} textAlign={"center"} className="indexUsername">
            {user.data?.username}
          </Typography>
          <Box textAlign={"center"}>
            {leftBoxIndexIconLinks.map((item, i) => {
              if (item.linkName === "Üzenetek | Üzenet küldése") {
                return user.data?.unread_msg! === 1 ? (
                  <Link mx={1} key={i} href={item.href} title={item.linkName}>
                    <Badge
                      className="index_leftBox_muiIcons_unReadMsg_dot"
                      badgeContent={1}
                      color="error"
                    >
                      <MailIcon className="index_leftBox_muiIcons_unReadMsg" />
                    </Badge>
                  </Link>
                ) : (
                  <Link key={i} href={item.href} title={item.linkName}>
                    <EmailIcon className="index_leftBox_muiIcons" />
                  </Link>
                );
              } else {
                return (
                  <Link key={i} href={item.href} title={item.linkName}>
                    {item.icon}
                  </Link>
                );
              }
            })}
          </Box>
          <Divider sx={{ width: "80%" }} className="form-divider" />
          {typeof user.data?.user_rank !== "undefined" && user.data.user_rank > 0 &&
          <Grid container textAlign={"center"}>
            <Grid item xs={12} my={1}>
              <Button
                href={"/app/support/messages/"}
                variant="contained"
                size="small"
                color="error"
              >
                Admin panel
              </Button>
            </Grid>
            <Divider sx={{ width: "80%" }} className="form-divider" />
          </Grid>
          }
          <Grid container>
            <Grid m={"auto"} mt={1} pl={1} item xs={5}>
              {user.data?.exp} XP
            </Grid>
            <Grid m={"auto"} textAlign={"right"} pr={1} item xs={5}>
              {user.data?.exp_end} XP
            </Grid>
            <Grid m={"auto"} p={1} item xs={11}>
              <BorderLinearProgress
                variant="determinate"
                value={getCurrentProgressPercent()}
              />
            </Grid>
            <Grid item xs={4}>
              <Avatar
                src="/icons/userbox/03.png"
                className="index_icons"
                variant="square"
              ></Avatar>
              <Typography className="index_icons_names">Szint</Typography>
              <Typography className="index_icons_values">
                {user.data?.level}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Avatar
                src="/icons/userbox/04.png"
                className="index_icons"
                variant="square"
              ></Avatar>
              <Typography className="index_icons_names">Tapasztalat</Typography>
              <Typography className="index_icons_values">
                {user.data?.exp}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Avatar
                src="/icons/userbox/05.png"
                className="index_icons"
                variant="square"
              ></Avatar>
              <Typography className="index_icons_names">TP szorzó</Typography>
              <Typography className="index_icons_values">
                {user.data?.exp_ratio} x
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Avatar
                src="/icons/userbox/06.png"
                className="index_icons"
                variant="square"
              ></Avatar>
              <Typography className="index_icons_names">Széria</Typography>
              <Typography className="index_icons_values">
                {user.data?.daily_series}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Avatar
                src="/icons/userbox/07.png"
                className="index_icons"
                variant="square"
              ></Avatar>
              <Typography className="index_icons_names">Kredit</Typography>
              <Typography className="index_icons_values">
                {user.data?.credit}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Avatar
                src="/icons/userbox/08.png"
                className="index_icons"
                variant="square"
              ></Avatar>
              <Typography className="index_icons_names">Sz. mentő</Typography>
              <Typography className="index_icons_values">
                {user.data?.freezer}
              </Typography>
            </Grid>
          </Grid>
          <UserHomeworkStatus user={user} />
        </Box>
      </Box>
    );
  }
}
