import { Button, CircularProgress } from "@mui/material";

export default function SubmitButton(props: {
  isLoading: boolean;
  buttonName: string;
  dataChanged?: undefined | boolean;
  myFunc?: () => void;
}): JSX.Element {
  if (typeof props.dataChanged !== "undefined" && props.dataChanged === false) {
    return (
      <div>
        <Button
          className="form-button"
          type="submit"
          size="large"
          fullWidth
          variant="contained"
          color="inherit"
          disabled
        >
          {props.buttonName}
        </Button>
        <p id="response_msg"></p>
      </div>
    );
  } else if (props.isLoading) {
    return (
      <div>
        <Button
          size="small"
          className="loadingButton"
          fullWidth
          variant="contained"
          disabled
        >
          <CircularProgress className="loadingButton-circular" />
        </Button>
        <p id="response_msg"></p>
      </div>
    );
  } else {
    return (
      <div>
        <Button
          className="form-button"
          type="submit"
          size="large"
          fullWidth
          variant="contained"
          color="inherit"
          onClick={props.myFunc}
        >
          {props.buttonName}
        </Button>
        <p id="response_msg"></p>
      </div>
    );
  }
}
