const Avatars = [
  {
    folderName: "happy",
    images: [
        "/avatars/happy/1.png", 
        "/avatars/happy/2.png", 
        "/avatars/happy/3.png", 
        "/avatars/happy/4.png", 
        "/avatars/happy/5.png", 
        "/avatars/happy/6.png", 
        "/avatars/happy/7.png", 
        "/avatars/happy/8.png", 
        "/avatars/happy/9.png", 
        "/avatars/happy/11.png", 
        "/avatars/happy/12.png", 
        "/avatars/happy/13.png", 
        "/avatars/happy/14.png", 
        "/avatars/happy/15.png", 
        "/avatars/happy/16.png", 
        "/avatars/happy/17.png", 
        "/avatars/happy/18.png", 
        "/avatars/happy/19.png", 
        "/avatars/happy/20.png", 
        "/avatars/happy/21.png", 
        "/avatars/happy/22.png", 
        "/avatars/happy/23.png", 
        "/avatars/happy/24.png", 
        "/avatars/happy/25.png", 
    ],
  },
  {
    folderName: "Állatok",
    images: [
        "/avatars/animals/1.png", 
        "/avatars/animals/2.png", 
        "/avatars/animals/3.png", 
        "/avatars/animals/4.png", 
        "/avatars/animals/5.png", 
        "/avatars/animals/6.png", 
        "/avatars/animals/7.png", 
        "/avatars/animals/8.png", 
        "/avatars/animals/9.png", 
        "/avatars/animals/11.png", 
        "/avatars/animals/12.png", 
        "/avatars/animals/13.png", 
        "/avatars/animals/14.png", 
        "/avatars/animals/15.png", 
        "/avatars/animals/16.png", 
    ],
  },
];

export default Avatars;
