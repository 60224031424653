import "./themes/happy.css";
import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import newAxios from "./axios/axios";

import Routing from "./routes/routes";
import { SessionResponse } from "./models/response/session";


function App() {
  // if session is ready start rendering the routes
  const [session, setSession] = useState(false);
  const [user, setUser] = useState({isLogged: false, avatar: ""});
  
  useEffect(() => {
    if(!session){
      const getSession = async () => {
        const response = await newAxios("/session", "GET", {}) as SessionResponse;
        if (response.status === 200) {
          setUser((prevState: any) => ({
            ...prevState,
            isLogged: response.data.isLogged,
            avatar: response.data.avatar,
          }));
          setSession(true);
        }
      };
      getSession();
    }
  }, [session]);
  // if session is not ready
  if (!session) {
    return null;
  } else {
    return (
      <BrowserRouter>
        <Routing user={user} setUser={setUser} />
      </BrowserRouter>
    );
  }
}

export default App;
