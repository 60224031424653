import { useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { TextField, Divider } from "@mui/material/";
import LockIcon from "@mui/icons-material/Lock";
import IconButton from "@mui/material/IconButton";

import SubmitButton from "../../../../components/ui/submitButton";
import newAxios from "../../../../axios/axios";
import Navigation from "./navigation";

import HU from "../../../../languages/HU/hu";
import { StatusResponse } from "../../../../models/response/status";

export default function UserPassword() {
  const [dataChanged, setDataChanged] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  async function sendNewPasswordToServer(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoadingButton(true);
    const [current_password, password, password2, response_msg] =
      document.querySelectorAll(
        "#current_password, #password, #password2, #response_msg"
      ) as unknown as [...HTMLFormElement[]];
    // setDataChanged(true);
    const response = (await newAxios("/app/settings/password", "POST", {
      currentPassword: current_password.value,
      newPassword: password.value,
      newPassword2: password2.value,
    })) as StatusResponse;
    setLoadingButton(false);
    response_msg.classList.remove("response_msg_success");
    if (response.status === 200 && response.data.success) {
      response_msg.classList.add("response_msg_success");
      response_msg.textContent = response.data.msg;
      // set button to disabled
      setDataChanged(false);
      current_password.value = "";
      password.value = "";
      password2.value = "";
    } else {
      response_msg.textContent = response.data.msg;
    }
  }

  function checkUserPasswordField() {
    const [current_password, password, password2, response_msg] =
      document.querySelectorAll(
        "#current_password, #password, #password2, #response_msg"
      ) as unknown as [...HTMLFormElement[]];
    if (response_msg.textContent !== "") {
      response_msg.textContent = "";
    }
    //if input value default and no password disable button
    if (
      current_password.value.length > 0 &&
      password.value.length > 0 &&
      password2.value.length > 0
    ) {
      setDataChanged(true);
    } else {
      setDataChanged(false);
    }
  }
  return (
    <Box className="settings-content">
      <Navigation />
      <Container
        className="settings-content-container"
        component="main"
        maxWidth="sm"
      >
        <Typography mb={3} variant="h5" sx={{ fontWeight: "bold" }}>
          {HU.user_settings.password.title}
          <Divider className="form-divider" />
        </Typography>
        <form onSubmit={sendNewPasswordToServer}>
          <TextField
            className="form_input_field"
            onChange={checkUserPasswordField}
            autoFocus
            margin="normal"
            required
            fullWidth
            name="current_password"
            id="current_password"
            type="password"
            placeholder={HU.user_settings.password.currentFieldPlaceholder}
            autoComplete="new-password"
            InputProps={{
              autoComplete: "new-password",
              startAdornment: (
                <div>
                  <IconButton disabled tabIndex={-1}>
                    <LockIcon />
                  </IconButton>
                </div>
              ),
            }}
          />
          <TextField
            className="form_input_field"
            onChange={checkUserPasswordField}
            margin="normal"
            required
            fullWidth
            name="password"
            id="password"
            type="password"
            placeholder={HU.user_settings.password.passwordFieldPlaceholder}
            autoComplete="new-password"
            InputProps={{
              autoComplete: "new-password",
              startAdornment: (
                <div>
                  <IconButton disabled tabIndex={-1}>
                    <LockIcon />
                  </IconButton>
                </div>
              ),
            }}
          />
          <TextField
            className="form_input_field"
            onChange={checkUserPasswordField}
            margin="normal"
            required
            fullWidth
            name="password2"
            id="password2"
            type="password"
            placeholder={HU.user_settings.password.password2FieldPlaceholder}
            autoComplete="new-password"
            InputProps={{
              autoComplete: "new-password",
              startAdornment: (
                <div>
                  <IconButton disabled tabIndex={-1}>
                    <LockIcon />
                  </IconButton>
                </div>
              ),
            }}
          />
          <SubmitButton
            isLoading={loadingButton}
            buttonName={HU.user_settings.password.submitButton}
            dataChanged={dataChanged}
          />
        </form>
      </Container>
    </Box>
  );
}
