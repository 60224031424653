import React from "react";

import { Button, Typography, TextField, Grid } from "@mui/material";
import removeDuplicatesAndSpaces from "../../../service/practice/removeDuplicatesAndSpaces";

export default function BadAnswerForm(props: any) {
  const { tasks, tasksStatus, getWords, updateWrongAnswerElement } = props;
  const getAdjectives = tasks[tasksStatus.currentTask].hun_adjectives
    ? ", " + tasks[tasksStatus.currentTask].hun_adjectives
    : "";
  const getNouns = tasks[tasksStatus.currentTask].hun_nouns
    ? ", " + tasks[tasksStatus.currentTask].hun_nouns
    : "";
  const getVerbs = tasks[tasksStatus.currentTask].hun_verbs
    ? ", " + tasks[tasksStatus.currentTask].hun_verbs
    : "";
  const getAdverbs = tasks[tasksStatus.currentTask].hun_adverbs
    ? ", " + tasks[tasksStatus.currentTask].hun_adverbs
    : "";
  const notUsableSolutions =
    tasks[tasksStatus.currentTask].hun +
    getAdjectives +
    getNouns +
    getVerbs +
    getAdverbs;
  const usableSolutions = removeDuplicatesAndSpaces(notUsableSolutions);
  return (
    <Grid textAlign={"center"} item xs={12}>
      <TextField
        className="form_input_field"
        margin="normal"
        fullWidth
        multiline
        rows={1}
        name="answer"
        id="answer"
        type="text"
        value={tasksStatus.userAnswer}
        required
        disabled
      />
      <Grid p={1} mb={2} xs={12} item>
        <Grid container>
          <Grid m={"auto"} xs={12} item>
            <Typography
              fontSize={20}
              textAlign={"center"}
              sx={{ color: "var(--myAlert)" }}
              fontWeight={600}
            >
              {tasksStatus.responseMsg}
            </Typography>
          </Grid>
          <Grid m={"auto"} xs={12} item>
            <Typography
              fontSize={17}
              mt={1}
              textAlign={"center"}
              fontWeight={600}
            >
              Megoldás(ok)
            </Typography>
            <Typography
              fontSize={17}
              mt={1}
              textAlign={"center"}
              sx={{ color: "gold" }}
              fontWeight={600}
            >
              {tasksStatus.type === 1
                ? getWords(
                    tasks[tasksStatus.currentTask].eng +
                      (tasks[tasksStatus.currentTask].eng_alternatives
                        ? "," + tasks[tasksStatus.currentTask].eng_alternatives
                        : "")
                  )
                : getWords(usableSolutions)}
            </Typography>
          </Grid>
          <Grid m={"auto"} xs={12} item>
            <Typography
              mt={1}
              textAlign={"center"}
              sx={{ color: "goldenrod" }}
              fontWeight={600}
            >
              Hibajelentés
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Button
        type="submit"
        size="large"
        variant="contained"
        className="form-button"
        onClick={updateWrongAnswerElement}
      >
        Tovább
      </Button>
    </Grid>
  );
}
