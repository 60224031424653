import { Grid, Typography, Button } from "@mui/material";
import BorderLinearProgress from "../progressBar";
import moment from "moment";
import "moment/locale/hu";

const getProgressPercent = (
  userDiscoveredWords: number,
  wordsCategoryTotalWords: number
) => {
  const catTotalWords =
    wordsCategoryTotalWords === null ? 0 : wordsCategoryTotalWords;
  const userCatWords = userDiscoveredWords === null ? 0 : userDiscoveredWords;
  if (userCatWords === 0 && catTotalWords === 0) {
    return 0;
  } else {
    const currentPercent = (userCatWords / catTotalWords) * 100;
    return currentPercent;
  }
};

const getSRSInfoElement = (props: any) => {
  if (props.userWordsStatus.SRSwords >= 10) {
    return (
      <Grid item md={6} sm={6} xs={12} m={"2px auto"}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              textAlign={"center"}
              className="index_rightbox_wordsTitle_beginner"
            >
              Gyakorlásra váró szavak
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography mx={1} className="index_rightbox_wordsTitle_beginner">
              {props.userWordsStatus.SRSwords}
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign={"right"}>
            <Typography mx={1} className="index_rightbox_wordsTitle_beginner">
              {props.userWordsStatus.userDiscoveredWords}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <BorderLinearProgress
              variant="determinate"
              value={getProgressPercent(
                props.userWordsStatus.SRSwords,
                props.userWordsStatus.userDiscoveredWords
              )}
            />
          </Grid>
          <Grid item xs={12} m={"5px auto"}>
            <Grid item xs={6} m={"2px auto"}>
              <Button
                className="form-button"
                type="submit"
                size="medium"
                fullWidth
                variant="contained"
                color="inherit"
                href="/app/practice/srs/"
                value={"srs_practice"}
              >
                Gyakorlás!
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  } else if (props.userWordsStatus.nextSRSLesson !== 0) {
    const futureSRSLesson = moment.unix(
      parseInt(props.userWordsStatus.nextSRSLesson, 10)
    );
    return (
      <Grid item md={6} sm={6} xs={12} m={"2px auto"}>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              borderBottom: "2px solid white",
              borderTop: "2px solid white",
              borderRadius: 3,
              padding: 1.5,
              margin: 3,
            }}
          >
            <Typography
              textAlign={"center"}
              className="index_rightbox_wordsTitle_beginner"
            >
              Következő SRS feladat elérhető:
            </Typography>
            <Typography
              textAlign={"center"}
              sx={{ textDecoration: "underline!important" }}
              className="index_rightbox_wordsTitle_beginner"
            >
              {moment(futureSRSLesson).startOf("seconds").fromNow()}
            </Typography>
            <Typography
              textAlign={"center"}
              sx={{
                textDecoration: "underline!important",
                fontWeight: "900px!important",
              }}
              className="index_rightbox_wordsTitle_beginner"
            >
              {moment(futureSRSLesson).calendar()}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid item md={6} sm={6} xs={12} m={"2px auto"}>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              borderBottom: "2px solid white",
              borderTop: "2px solid white",
              borderRadius: 3,
              padding: 1.5,
              margin: 3,
            }}
          >
            <Typography
              textAlign={"center"}
              className="index_rightbox_wordsTitle_beginner"
            >
              Következő SRS feladat elérhető:
            </Typography>
            <Typography
              textAlign={"center"}
              sx={{ textDecoration: "underline!important" }}
              className="index_rightbox_wordsTitle_beginner"
            >
              Még nem fedeztél fel szavakat!
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }
};

export default function SRSInfoElement(props: { userSRS: any }) {
  return getSRSInfoElement(props.userSRS);
}
