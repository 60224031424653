let isVoiceActive = false;
const getUserBrowser = () => {
  if (
    (navigator.userAgent.indexOf("Opera") ||
      navigator.userAgent.indexOf("OPR")) !== -1
  ) {
    return "Opera";
  } else if (navigator.userAgent.indexOf("Edg") !== -1) {
    return "Edge";
  } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    return "Chrome";
  } else if (navigator.userAgent.indexOf("Safari") !== -1) {
    return "Safari";
  } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    return "Firefox";
  } else {
    return "unknown";
  }
};
/* target is US or UK what sound type user want, word is an english word or sentence */
const playTheText = (target: string, word: string) => {
  return new Promise<void>((res) => {
    if (!isVoiceActive) {
      const userBrowser = getUserBrowser();
      isVoiceActive = true;
      const synth = window.speechSynthesis;
      let voices: SpeechSynthesisVoice[], utterance: SpeechSynthesisUtterance;
      utterance = new SpeechSynthesisUtterance(word);

      /* select target voice in different browsers */
      const setTheVoiceBasedOnBrowser = () => {
        if (userBrowser === "Chrome") {
          let randomUK = Math.round(Math.random() * 1);
          let getRandomUK = randomUK === 0 ? voices[3] : voices[4]; // 3 en-GB UK woman 4 en-GB UK man
          utterance.voice = target === "US" ? voices[2] : getRandomUK;
          utterance.lang = target === "US" ? "en-US" : "en-GB";
        } else if (userBrowser === "Edge") {
          let randomUK = voices[106]; // UK sounds 103-107
          let randomUS = voices[115]; // US sounds 108-124
          utterance.voice = target === "UK" ? randomUK : randomUS;
          utterance.lang = target === "US" ? "en-US" : "en-GB";
        } else if (userBrowser === "Firefox") {
          utterance.voice = voices[1]; // only one us
        } else {
          utterance.lang = target === "US" ? "en-US" : "en-GB";
        }
      };
      // wait for it while the voices are ready
      const checkTheVoicesLength = () => {
        voices = synth.getVoices();
        if (voices.length === 0) {
          // If voices are not loaded, retry after a short delay
          setTimeout(checkTheVoicesLength, 100);
        } else {
          setTheVoiceBasedOnBrowser();
          synth.speak(utterance);
          utterance.onend = () => {
            isVoiceActive = false;
            synth.cancel();
            res();
          };
        }
      };

      checkTheVoicesLength();
    }
  });
};

export default playTheText;
