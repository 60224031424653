import { useState } from "react";
import { Box, Container, Typography, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import GradeIcon from "@mui/icons-material/Grade";

import newAxios from "../../../../axios/axios";
import SubmitButton from "../../../../components/ui/submitButton";

import HU from "../../../../languages/HU/hu";
import Navigation from "./navigation";

import { SelectChangeEvent } from "@mui/material/";
import { StatusResponse } from "../../../../models/response/status";

export default function Rewards() {
  const [selectValue, setSelectValue] = useState("one");
  const [loadingButton, setLoadingButton] = useState(false);

  async function handleCreditReward(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoadingButton(true);
    const reward = document.getElementById("value") as HTMLFormElement;
    const username = document.getElementById("username") as HTMLFormElement;
    const response_msg = document.getElementById("response_msg") as HTMLElement;
    const response = (await newAxios("/app/support/rewards/", "POST", {
      reward: reward.value,
      target: selectValue,
      username: username !== null ? username.value : "notOne",
    })) as StatusResponse;
    setLoadingButton(false);
    response_msg.classList.remove("response_msg_success");
    if (response.status === 200 && response.data.success) {
      response_msg.classList.add("response_msg_success");
      response_msg.textContent = response.data.msg;
      reward.value = "";
      if (selectValue === "one") {
        username.value = "";
      }
    } else {
      response_msg.textContent = response.data.msg;
    }
  }
  function updateSelectValue(e: SelectChangeEvent<string>) {
    setSelectValue(e.target.value);
  }
  return (
    <Box className="settings-content">
      <Navigation />
      <Container sx={{ marginBottom: 5, padding: "1rem 0.5rem" }} maxWidth="xs">
        <Typography mb={3} variant="h5" sx={{ fontWeight: "bold" }}>
          {HU.support_settings.rewards.creditsTitle}
        </Typography>
        <FormControl className="support_messages_selector">
          <Select
            sx={{ padding: 1 }}
            value={selectValue}
            onChange={updateSelectValue}
            autoWidth
          >
            <MenuItem value={"one"}>
              {HU.support_settings.rewards.menuOne}
            </MenuItem>
            <MenuItem value={"subs"}>
              {HU.support_settings.rewards.menuSubs}
            </MenuItem>
            <MenuItem value={"all"}>
              {HU.support_settings.rewards.menuAll}
            </MenuItem>
          </Select>
        </FormControl>
        <form onSubmit={handleCreditReward}>
          {selectValue === "one" && (
            <TextField
              className="form_input_field"
              margin="normal"
              required
              fullWidth
              id="username"
              name="username"
              type="text"
              placeholder={HU.support_settings.rewards.usernameField}
              InputProps={{
                startAdornment: (
                  <IconButton disabled tabIndex={-1}>
                    <InsertEmoticonIcon />
                  </IconButton>
                ),
              }}
            />
          )}
          <TextField
            className="form_input_field"
            margin="normal"
            required
            fullWidth
            id="value"
            name="value"
            type="number"
            placeholder={HU.support_settings.rewards.valueField}
            InputProps={{
              startAdornment: (
                <IconButton disabled tabIndex={-1}>
                  <GradeIcon />
                </IconButton>
              ),
            }}
          />
          <SubmitButton
            isLoading={loadingButton}
            buttonName={HU.support_settings.rewards.submitButton}
          />
        </form>
      </Container>
    </Box>
  );
}
