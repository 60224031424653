import React from "react";

import { Box, IconButton, Typography } from "@mui/material";

import PlayCircleFilledTwoToneIcon from "@mui/icons-material/PlayCircleFilledTwoTone";
import playTheText from "../../service/practice/playText";

export default function SentencePlayButton(props: any) {
  const { tasks, tasksStatus } = props;
  return (
    <Box display={"inline"}>
      <IconButton
        onClick={() =>
          playTheText("UK", tasks[tasksStatus.currentTask].eng_sent)
        }
        sx={{ cursor: "auto", padding: 0, marginLeft: 1 }}
        disableRipple
      >
        <Typography color={"white"} fontWeight={600}>
          UK
        </Typography>
        <PlayCircleFilledTwoToneIcon className="practice_words_play_first_icon" />
      </IconButton>
      <IconButton
        onClick={() =>
          playTheText("US", tasks[tasksStatus.currentTask].eng_sent)
        }
        sx={{ cursor: "auto", padding: 0, marginLeft: 1 }}
        disableRipple
      >
        <Typography color={"white"} fontWeight={600}>
          US
        </Typography>
        <PlayCircleFilledTwoToneIcon className="practice_words_play_first_icon" />
      </IconButton>
    </Box>
  );
}
