import { Hidden } from "@mui/material";

import ComputerFormat from "./computerFormat/computerFormat";
import PhoneFormat from "./phoneFormat/phoneFormat";

export default function Index() {
  return (
    <div>
      <Hidden only={["xs", "sm"]}>
        <ComputerFormat />
      </Hidden>
      <Hidden only={["lg", "md", "xl"]}>
        <PhoneFormat />
      </Hidden>
    </div>
  );
}
