// its only preview wont send it
export default function EmailStructure(data: string) {
  const appName = "appnév";
  return `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
<head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
    <style type="text/css">
        body {
            margin: 0;
            padding: 0;
            }
        .email-container {
            font-family: Roboto-Regular, Helvetica, Arial, sans-serif;
            color: rgb(215, 204, 204);
            max-width: 600px;
            margin: 0 auto;
            background-color: #20232A;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            text-align: center;
            border: 1px solid white;
        }
        .header {
            background-color: #20232A;
            padding: 10px 0;
            border-radius: 10px 10px 0 0;
        }
        .header h1 {
            color: rgb(138, 205, 67);
            margin: 0;
        }
        .banner {
            width: 100%;
            height: 150px;
            background-color: #dddddd;
            margin: 20px 0;
            border-radius: 10px;
        }
        .content h2 {
            text-align: center;
            color: rgb(138, 205, 67)!important;
        }
        .content p {
            color: rgb(215, 204, 204)!important;
            font-size: 17px!important;
            font-weight: 400!important;
            margin: 2px!important
        }
        .content a {
            color: rgb(138, 205, 67)
        }
        .button {
            background-color: rgb(138, 205, 67);
            color: #FFFFFF!important;
            padding: 10px 20px;
            text-decoration: none;
            border-radius: 5px;
            display: inline-block;
            margin: 20px 0;
            font-weight: bold;
        }
        .footer {
            text-align: center;
            font-size: 12px;
        }
        .footer p {
            margin: 5px 0;
            color: rgb(215, 204, 204)!important;
        }
        .footer a {
            color: rgb(138, 205, 67)!important; /* #007BFF */
            text-decoration: none;
        }
        .separator {
            border-top: 1px solid #ccc;
            margin: 20px 0;
        }
        .custom-content {
            text-align: left;
            color: rgb(215, 204, 204)!important;
            font-size: 17px!important;
            font-weight: 400!important;
            margin: 2px!important
        }    
    </style>
</head>
<body>
    <div class="email-container">
        <div class="header">
            <h1>${appName}</h1>
        </div>
        <div class="banner">
            <!-- <img src="https://via.placeholder.com/600x150.png?text=Default+Banner" alt="Banner Image" style="width: 100%; height: auto; border-radius: 10px;" /> -->
        </div>
        <div class="content">
            <h2>Kedves Felhasználó!</h2>

            <div class="custom-content">${data}</div>

            <div class="separator"></div>
            <p>Köszönjük, hogy használod szolgáltatásunkat!</p>
            <p>A ${appName} csapata</p>
        </div>
        <div class="separator"></div>
        <div class="footer">
            <p>&copy; 2023-2024 ${appName}</p>
            <p>Ha fel akarod venni a kapcsolatot velünk, kérjük <a href="mailto:@">írj nekünk</a>.</p>
            <p>Ha szeretnél leiratkozni a további értesítésekről, kattints az alábbi linkre:</p>
            <p><a href="#">Leiratkozás</a></p>
        </div>
    </div>
</body>
</html>
    `;
}
