import Grid from "@mui/material/Grid";
import LearningMainPage from "../learning/learningMainPage";
import UserInfo from "./boxes/userInfo";

export default function ComputerFormat() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4} xl={3} lg={3}>
        <UserInfo />
      </Grid>
      <Grid item xs={12} sm={9} md={8} xl={9} lg={9}>
        <LearningMainPage />
      </Grid>
    </Grid>
  );
}