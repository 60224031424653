import React from "react";

import { TextField, Grid } from "@mui/material";
import SubmitButton from "../../ui/submitButton";

export default function AnswerForm(props: any) {
  const { tasksStatus, formData, setFormData, userAswerHandler } = props;
  // set the button to active if fields are not empty
  function checkFields() {
    const answer = document.getElementById("answer") as HTMLFormElement;
    //if input value default and no password disable button
    if (answer.value.length > 0) {
      setFormData((prevState: any) => ({
        ...prevState,
        answer: answer.value.length,
        dataChanged: true,
      }));
    } else {
      setFormData((prevState: any) => ({
        ...prevState,
        answer: 0,
        dataChanged: false,
      }));
    }
  }

  return (
    <Grid item xs={12}>
      <TextField
        className="form_input_field"
        onChange={checkFields}
        margin="normal"
        fullWidth
        multiline
        rows={1}
        name="answer"
        id="answer"
        type="text"
        placeholder={
          tasksStatus.type === 0 ? "Írd be magyarul" : "Írd be angolul"
        }
        autoFocus
        required
        disabled={formData.isFormDisabled}
      />
      <SubmitButton
        isLoading={formData.isLoading}
        dataChanged={formData.dataChanged}
        buttonName="Ellenőrzés"
        myFunc={userAswerHandler}
      />
    </Grid>
  );
}
