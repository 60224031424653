import React from "react";
import { Grid } from "@mui/material";

import SRSInfoElement from "./SRSInfoElement";
import DiscoveredWordsInfoElements from "./discoveredWordsInfoElements";
import WordsThemesPagination from "./wordsThemesPagination";

export default function WordsHeaderContainer(props: any) {
  return (
    <Grid container spacing={1}>
      {/* SRS SYSTEMINFO */}
      <Grid item xs={12}>
        <SRSInfoElement userSRS={props} />
      </Grid>
      {/* SRS system end*/}
      <Grid item xs={12}>
        <DiscoveredWordsInfoElements userSRS={props} />
      </Grid>
      <Grid item xs={12}>
        <WordsThemesPagination userSRS={props} />
      </Grid>
    </Grid>
  );
}
