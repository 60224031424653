import React from "react";

import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import MoodBadIcon from "@mui/icons-material/MoodBad";

import { Button, Typography, TextField, Grid } from "@mui/material";
import removeDuplicatesAndSpaces from "../../../service/practice/removeDuplicatesAndSpaces";

export default function GoodAnswerForm(props: any) {
  const {
    tasks,
    tasksStatus,
    getWords,
    pushCurrentTaskIntoFinishedTasks,
    nextTask,
  } = props;
  const getAdjectives = tasks[tasksStatus.currentTask].hun_adjectives
    ? ", " + tasks[tasksStatus.currentTask].hun_adjectives
    : "";
  const getNouns = tasks[tasksStatus.currentTask].hun_nouns
    ? ", " + tasks[tasksStatus.currentTask].hun_nouns
    : "";
  const getVerbs = tasks[tasksStatus.currentTask].hun_verbs
    ? ", " + tasks[tasksStatus.currentTask].hun_verbs
    : "";
  const getAdverbs = tasks[tasksStatus.currentTask].hun_adverbs
    ? ", " + tasks[tasksStatus.currentTask].hun_adverbs
    : "";
  const notUsableSolutions =
    tasks[tasksStatus.currentTask].hun +
    getAdjectives +
    getNouns +
    getVerbs +
    getAdverbs;
  const usableSolutions = removeDuplicatesAndSpaces(notUsableSolutions);
  const getIrregular = (eng: string | undefined, hun: string | undefined) => {
    if (typeof eng !== "undefined" || typeof hun !== "undefined") {
      const modifiedEng = eng?.replace(/,/g, ", ");
      return (
        <Typography
          fontSize={17}
          mt={0.3}
          textAlign={"center"}
          fontWeight={600}
        >
          {modifiedEng + " - "}
          <Typography
            fontSize={17}
            mt={0.3}
            textAlign={"center"}
            fontWeight={600}
            sx={{ color: "gold" }}
            component={"span"}
          >
            {hun}
          </Typography>
        </Typography>
      );
    }
  };
  const getPlurals = (eng: string | undefined, hun: string | undefined) => {
    if (typeof eng !== "undefined" || typeof hun !== "undefined") {
      return (
        <Typography
          fontSize={17}
          mt={0.3}
          textAlign={"center"}
          fontWeight={600}
        >
          {eng + " - "}
          <Typography
            fontSize={17}
            mt={0.3}
            textAlign={"center"}
            fontWeight={600}
            sx={{ color: "gold" }}
            component={"span"}
          >
            {hun}
          </Typography>
        </Typography>
      );
    }
  };
  const getAdjectiveInc = (
    eng: string | undefined,
    hun: string | undefined
  ) => {
    if (typeof eng !== "undefined" || typeof hun !== "undefined") {
      const splitEngAdj = eng?.split(",");
      const splitHunAdj = hun?.split(",");
      const allConcatedAdj = [];
      if (
        typeof splitEngAdj !== "undefined" &&
        typeof splitHunAdj !== "undefined"
      ) {
        for (let i = 0; i < splitEngAdj.length; i++) {
          allConcatedAdj.push(
            <Typography
              fontSize={17}
              mt={0.3}
              textAlign={"center"}
              fontWeight={600}
              key={i}
            >
              {splitEngAdj[i] + " - "}
              <Typography
                fontSize={17}
                mt={0.3}
                textAlign={"center"}
                fontWeight={600}
                sx={{ color: "gold" }}
                component={"span"}
              >
                {splitHunAdj[i]}
              </Typography>
            </Typography>
          );
        }
        return <span>{allConcatedAdj}</span>;
      }
    }
  };
  return (
    <Grid item xs={12}>
      <TextField
        className="form_input_field"
        margin="normal"
        fullWidth
        multiline
        rows={1}
        name="answer"
        id="answer"
        type="text"
        placeholder={
          tasksStatus.type === 1 ? "Írd be magyarul" : "Írd be angolul"
        }
        value={tasksStatus.userAnswer}
        required
        disabled
      />
      <Grid container>
        <Grid p={1} xs={12} item>
          <Grid container>
            <Grid m={"auto"} xs={12} item>
              <Typography
                fontSize={20}
                textAlign={"center"}
                sx={{ color: "greenyellow" }}
                fontWeight={600}
              >
                {tasksStatus.responseMsg}
              </Typography>
            </Grid>
            <Grid m={"auto"} xs={12} item>
              <Typography
                fontSize={17}
                mt={1}
                textAlign={"center"}
                fontWeight={600}
              >
                Megoldás(ok)
              </Typography>
              <Typography
                fontSize={17}
                mt={1}
                textAlign={"center"}
                fontWeight={600}
                sx={{ color: "gold" }}
              >
                {tasksStatus.type === 1
                  ? getWords(
                      tasks[tasksStatus.currentTask].eng +
                        (tasks[tasksStatus.currentTask].eng_alternatives
                          ? "," +
                            tasks[tasksStatus.currentTask].eng_alternatives
                          : "")
                    )
                  : getWords(usableSolutions)}
              </Typography>
            </Grid>
            {tasksStatus.type === 1 &&
              typeof tasks[tasksStatus.currentTask].eng_plural === "string" &&
              tasks[tasksStatus.currentTask].eng_plural.length > 0 && (
                <Grid m={"auto"} xs={12} item>
                  <Typography
                    fontSize={17}
                    p={1}
                    textAlign={"center"}
                    fontWeight={600}
                  >
                    Többes-szám
                  </Typography>
                  {getPlurals(
                    tasks[tasksStatus.currentTask].eng_plural,
                    getWords(tasks[tasksStatus.currentTask].hun_plural)
                  )}
                </Grid>
              )}
            <Grid m={"auto"} xs={12} item>
              <Typography
                mt={1}
                textAlign={"center"}
                sx={{ color: "goldenrod" }}
                fontWeight={600}
              >
                Hibajelentés
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* if the task already used more than once */}
        {tasks[tasksStatus.currentTask].isUsed ? (
          <Grid pr={1} mt={2} xs={12} textAlign={"center"} item>
            <Button
              type="submit"
              size="large"
              variant="contained"
              className="form-button"
              onClick={() => nextTask(true)}
            >
              Tovább eredményre
            </Button>
          </Grid>
        ) : (
          <Grid container>
            {" "}
            {/* first encounter with the task */}
            <Grid p={1} xs={12} item>
              <Typography my={1} textAlign={"center"} fontWeight={600}>
                Mennyire tudtad?
              </Typography>
            </Grid>
            <Grid pr={1} xs={3} item>
              <Button
                type="submit"
                size="medium"
                fullWidth
                variant="contained"
                color="error"
                onClick={() => pushCurrentTaskIntoFinishedTasks("1")}
              >
                <MoodBadIcon fontSize="large" />
              </Button>
            </Grid>
            <Grid pr={1} xs={3} item>
              <Button
                type="submit"
                size="medium"
                fullWidth
                variant="contained"
                color="warning"
                onClick={() => pushCurrentTaskIntoFinishedTasks("2")}
              >
                <SentimentNeutralIcon fontSize="large" />
              </Button>
            </Grid>
            <Grid pr={1} xs={3} item>
              <Button
                type="submit"
                size="medium"
                fullWidth
                variant="contained"
                color="info"
                onClick={() => pushCurrentTaskIntoFinishedTasks("3")}
              >
                <SentimentSatisfiedIcon fontSize="large" />
              </Button>
            </Grid>
            <Grid xs={3} item>
              <Button
                type="submit"
                size="medium"
                fullWidth
                variant="contained"
                color="success"
                onClick={() => pushCurrentTaskIntoFinishedTasks("4")}
              >
                <SentimentVerySatisfiedIcon fontSize="large" />
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
