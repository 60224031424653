import React, { useState, useEffect } from "react";

import CheckingWords from "../../service/practice/checkingWords";
import removeDuplicatesAndSpaces from "../../service/practice/removeDuplicatesAndSpaces";

import BadAnswerForm from "./forms/badAnswerForm";
import GoodAnswerForm from "./forms/goodAnswerForm";
import AnswerForm from "./forms/answerForm";
import playTheText from "../../service/practice/playText";

export default function GetActiveForm(props: any) {
  const {
    tasks,
    tasksStatus,
    setTasks,
    setTasksStatus,
    getWords,
    finishedTasks,
    setFinishedTasks,
    setIsHelpActive,
  } = props;
  const [currentForm, setCurrentForm] = useState(0);
  const [isAnswerHandlerActive, setIsAnswerHandlerActive] = useState(false);
  const [formData, setFormData] = useState({
    answer: 0,
    dataChanged: false,
    isLoading: false,
    isFormDisabled: false,
  });

  const getNextTaskType = (notMaxEngTask: boolean, notMaxHunTask: boolean) => {
    const currentTypeNumber = Math.round(Math.random() * 1);
    if (currentTypeNumber === 0 && notMaxEngTask) {
      return currentTypeNumber;
    } else if (currentTypeNumber === 1 && notMaxHunTask) {
      return currentTypeNumber;
    } else if (currentTypeNumber === 0 && !notMaxEngTask) {
      return 1;
    } else if (currentTypeNumber === 1 && !notMaxHunTask) {
      return 0;
    } else {
      return currentTypeNumber;
    }
  };

  const nextTask = (wasGoodAnswer: boolean) => {
    // reset the answer form state
    setFormData((prevState) => ({
      ...prevState,
      answer: 0,
      dataChanged: false,
    }));
    const answer = document.getElementById("answer") as HTMLFormElement;
    if (tasks.length === tasksStatus.currentProgress) {
      // tasks are finished
      setTasksStatus((prevState: any) => ({
        ...prevState,
        type: 2, // reward page
      }));
    } else {
      answer.value = "";
      const isNotMaximumEngTask = tasksStatus.engToHunTasks < tasks.length / 2;
      const isNotMaximumHunTask = tasksStatus.hunToEngTasks < tasks.length / 2;
      const isLastTask = isNotMaximumEngTask || isNotMaximumHunTask;
      const getType =
        isLastTask && getNextTaskType(isNotMaximumEngTask, isNotMaximumHunTask);
      const safeValue =
        tasksStatus.currentTask + 1 === tasks.length
          ? tasks.length - 1
          : tasksStatus.currentTask + 1;
      setTasksStatus((prevState: any) => ({
        // go next task
        ...prevState,
        currentTask: wasGoodAnswer ? safeValue : prevState.currentTask,
        type: isLastTask ? getType : prevState.type,
        currentForm: 0,
        engToHunTasks:
          isNotMaximumEngTask && wasGoodAnswer && getType === 0
            ? prevState.engToHunTasks + 1
            : prevState.engToHunTasks,
        hunToEngTasks:
          isNotMaximumHunTask && wasGoodAnswer && getType === 1
            ? prevState.hunToEngTasks + 1
            : prevState.hunToEngTasks,
        totalTry: prevState.totalTry + 1,
      }));
      setCurrentForm(0);
    }
  };

  const pushCurrentTaskIntoFinishedTasks = (key: string | undefined) => {
    const currentWordID = tasks[tasksStatus.currentTask].w_id;
    const currentRe_practisedValue =
      tasks[tasksStatus.currentTask].re_practised;
    const newFinishedTasks = finishedTasks;
    let userRate = 0;
    switch (key) {
      case "1":
        userRate = -2;
        break;
      case "2":
        userRate = -1;
        break;
      case "3":
        userRate = 1;
        break;
      case "4":
        userRate = 2;
        break;
    }
    const userTaskInfo = {
      w_id: currentWordID,
      w_rate: userRate,
      re_practised: currentRe_practisedValue,
    };
    newFinishedTasks.push(userTaskInfo);
    setFinishedTasks(newFinishedTasks);
    nextTask(true);
  };

  const userAswerHandler = async () => {
    try {
      setFormData((prevState: any) => ({
        ...prevState,
        isFormDisabled: true,
        isLoading: true,
      }));
      const getTasksStatus = props.tasksStatus;
      const allTasks = props.tasks;
      await playTheText("UK", allTasks[getTasksStatus.currentTask].eng);
      const answer = document.getElementById("answer") as HTMLFormElement;
      const userAnswer = answer.value;
      const currentFormType = getTasksStatus.type;
      const getAdjectives = allTasks[getTasksStatus.currentTask].hun_adjectives
        ? ", " + allTasks[getTasksStatus.currentTask].hun_adjectives
        : "";
      const getNouns = allTasks[getTasksStatus.currentTask].hun_nouns
        ? ", " + allTasks[getTasksStatus.currentTask].hun_nouns
        : "";
      const getVerbs = allTasks[getTasksStatus.currentTask].hun_verbs
        ? ", " + allTasks[getTasksStatus.currentTask].hun_verbs
        : "";
      const getAdverbs = allTasks[getTasksStatus.currentTask].hun_adverbs
        ? ", " + allTasks[getTasksStatus.currentTask].hun_adverbs
        : "";
      const notUsableSolutions =
        allTasks[getTasksStatus.currentTask].hun +
        getAdjectives +
        getNouns +
        getVerbs +
        getAdverbs;
      const usableSolutions = removeDuplicatesAndSpaces(notUsableSolutions);
      const solutions =
        currentFormType === 0
          ? usableSolutions
          : getWords(allTasks[getTasksStatus.currentTask].eng) +
            "," +
            allTasks[getTasksStatus.currentTask].eng_alternatives;
      setIsHelpActive(false); // disable help switch if it was active
      const [isCorrectAnswer, responseMsg, mistakePoint] = (await CheckingWords(
        solutions,
        userAnswer,
        currentFormType
      )) as [boolean, string, number];
      const successRatePercentPerTask = allTasks.length - mistakePoint;
      // isUsedTask default is not used, true if used
      const isUsedTask = allTasks[getTasksStatus.currentTask].isUsed;
      const isNotUsedTask = typeof isUsedTask === "undefined";
      const newSuccessRate = isNotUsedTask
        ? getTasksStatus.successRate + successRatePercentPerTask
        : getTasksStatus.successRate;
      const newTotalGoodAnswer = isNotUsedTask
        ? getTasksStatus.totalGoodAnswer + 1
        : getTasksStatus.totalGoodAnswer;
      const newSmallMistakesPoint =
        isNotUsedTask && mistakePoint > 0
          ? getTasksStatus.smallMistakes + 1
          : getTasksStatus.smallMistakes;
      if (isCorrectAnswer) {
        // good answer
        setTasksStatus((prevState: any) => ({
          ...prevState,
          currentProgress: prevState.currentProgress + 1,
          userAnswer: userAnswer,
          currentForm: 1,
          responseMsg,
          successRate: newSuccessRate,
          totalGoodAnswer: newTotalGoodAnswer,
          smallMistakes: newSmallMistakesPoint,
        }));
        setFormData((prevState: any) => ({
          ...prevState,
          isFormDisabled: false,
          isLoading: false,
        }));
        setCurrentForm(1);
        setIsAnswerHandlerActive(false);
      } else {
        const newTotalWrongAnswer = isNotUsedTask
          ? getTasksStatus.totalWrongAnswer + 1
          : getTasksStatus.totalWrongAnswer;
        setTasksStatus((prevState: any) => ({
          // bad answer
          ...prevState,
          userAnswer: userAnswer,
          currentForm: 2,
          responseMsg,
          totalWrongAnswer: newTotalWrongAnswer,
        }));
        setFormData((prevState: any) => ({
          ...prevState,
          isFormDisabled: false,
          isLoading: false,
        }));
        setCurrentForm(2);
        setIsAnswerHandlerActive(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateWrongAnswerElement = () => {
    const isFirstBadAnswerWithThisTask =
      typeof tasks[tasksStatus.currentTask].isUsed !== "boolean";
    // if wrong answer but first encounter with. Push it to finished tasks and Push it to end of tasks
    if (isFirstBadAnswerWithThisTask) {
      const currentWordID = tasks[tasksStatus.currentTask].w_id;
      const currentRe_practisedValue =
        tasks[tasksStatus.currentTask].re_practised;
      const newFinishedTasks = finishedTasks;
      // if the answer bad automaticly -2
      const userRate = -2;
      const userTaskInfo = {
        w_id: currentWordID,
        w_rate: userRate,
        re_practised: currentRe_practisedValue,
      };
      newFinishedTasks.push(userTaskInfo);
      setFinishedTasks(newFinishedTasks);
      pushWrongTaskToTasksEnd();
    }
    // if wrong answer but not first encounter with. Push it to end of tasks
    else {
      pushWrongTaskToTasksEnd();
    }
  };

  const pushWrongTaskToTasksEnd = () => {
    const allTasks = tasks;
    const getCurrentTask = allTasks[tasksStatus.currentTask];
    const newKeyValue = { isUsed: true };
    Object.assign(getCurrentTask, newKeyValue);
    // eslint-disable-next-line
    const currentTask = allTasks.splice(getCurrentTask, 1);
    allTasks.push(getCurrentTask);
    setTasks(allTasks);
    nextTask(false);
  };

  useEffect(() => {
    if (currentForm === 0) {
      const answer = document.getElementById("answer") as HTMLFormElement;
      answer.focus();
    }
    const handlekeydownEvent = (event: KeyboardEvent) => {
      const isUnusedTask =
        typeof tasks[tasksStatus.currentTask].isUsed !== "boolean";
      const { key } = event;
      // answer form and answer lenght 0
      if (key === "Enter" && currentForm === 0 && formData.answer === 0) {
        event.preventDefault();
      }
      // answer form but user writed something
      else if (key === "Enter" && currentForm === 0 && formData.answer > 0) {
        event.preventDefault();
        setIsAnswerHandlerActive(true);
        userAswerHandler();
      }
      // if it was a good answer but not first encounter with the task
      else if (key === "Enter" && currentForm === 1 && !isUnusedTask) {
        event.preventDefault();
        nextTask(true);
      }
      // good answer form and first encounter with the task
      else if (
        (key === "1" && currentForm === 1 && isUnusedTask) ||
        (key === "2" && currentForm === 1 && isUnusedTask) ||
        (key === "3" && currentForm === 1 && isUnusedTask) ||
        (key === "4" && currentForm === 1 && isUnusedTask)
      ) {
        event.preventDefault();
        pushCurrentTaskIntoFinishedTasks(key);
      }
      // bad answer form
      else if (key === "Enter" && currentForm === 2) {
        event.preventDefault();
        updateWrongAnswerElement();
      }
    };
    if (!isAnswerHandlerActive) {
      document.addEventListener("keydown", handlekeydownEvent);
      return () => {
        document.removeEventListener("keydown", handlekeydownEvent);
      };
    }
  });

  if (tasksStatus.currentForm === 0) {
    return (
      <AnswerForm
        tasksStatus={tasksStatus}
        formData={formData}
        setFormData={setFormData}
        userAswerHandler={userAswerHandler}
      />
    );
  } else if (tasksStatus.currentForm === 1) {
    return (
      <GoodAnswerForm
        tasks={tasks}
        tasksStatus={tasksStatus}
        getWords={getWords}
        pushCurrentTaskIntoFinishedTasks={pushCurrentTaskIntoFinishedTasks}
        nextTask={nextTask}
      />
    );
  } else {
    return (
      <BadAnswerForm
        tasks={tasks}
        tasksStatus={tasksStatus}
        getWords={getWords}
        updateWrongAnswerElement={updateWrongAnswerElement}
      />
    );
  }
}
