import React from "react";

import { Grid, Typography, Switch } from "@mui/material";

export default function HelpSwitch(props: any) {
  const { setHelpStatus, isHelpActive } = props;
  return (
    <Grid item>
      <Typography textAlign={"right"} fontWeight={600}>
        Példa
        <Switch
          className="options_switch"
          checked={isHelpActive}
          size="small"
          onChange={setHelpStatus}
        />
      </Typography>
    </Grid>
  );
}
