import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import { Hidden } from "@mui/material";

import HU from "../../languages/HU/hu";
import Links from "../../languages/HU/links";

const logoName = Links.siteName;
const pages = Links.protectedHeader;
const settings = Links.protectedHeaderUserLinks;

export default function ProtectedHeader(props: {
  userAvatar: string;
}): JSX.Element {
  const [anchorElNav, setAnchorElNav]: any = React.useState(null);
  const [anchorEl, setAnchorEl]: any = React.useState(null);
  const open = Boolean(anchorEl);

  const handleOpenNavMenu = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (): void => {
    setAnchorElNav(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <Hidden only={['xs', 'sm']}>
    <AppBar className="mainHeader" color="default" position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
          <Typography
            className="logoname"
            variant="h6"
            noWrap
            component="a"
            href="/app"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".1rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            {logoName}
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              className="menu_dropdown_container"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((item, index) => (
                <MenuItem key={index} onClick={handleCloseNavMenu}>
                  <Link
                    className="header-links"
                    href={item.href}
                    sx={{ textDecoration: "none" }}
                  >
                    <Typography textAlign="center">{item.linkName}</Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
          <Typography
            className="logoname"
            variant="h5"
            noWrap
            component="a"
            href="/app"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".1rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            {logoName}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((item, index) => (
              <MenuItem className="protectedHeaderIcons" key={index} onClick={handleCloseNavMenu}>
                <Link
                  key={index}
                  className="header-links"
                  href={item.href}
                  sx={{
                    my: 2,
                    color: "inherit",
                    fontWeight: "600",
                    display: "block",
                    textDecoration: "none",
                  }}
                >
                  <Avatar
                    src={item.icon}
                    variant="square"
                    sx={{ marginRight: "1rem!important" }}
                  ></Avatar>
                </Link>
                {item.linkName}
              </MenuItem>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {/*  right end menu starts */}
            <Tooltip title={HU.protectedHeader.userSettingsMenuTitle}>
              <IconButton
                onClick={handleClick}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar
                  src={props.userAvatar}
                  sx={{ width: 50, height: 50 }}
                ></Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 2,
                  ml: 0,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 25,
                    width: 18,
                    height: 18,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {settings.map((item, index) => (
                <Link
                  key={index}
                  className="header-links"
                  href={item.href}
                  sx={{ textDecoration: "none", color: "inherit" }}
                >
                  <MenuItem>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    {item.linkName}
                  </MenuItem>
                  {item.afterDivider && <Divider className="form-divider" />}
                </Link>
              ))}
            </Menu>
            {/*  right end menu ends */}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
    </Hidden>
  );
}
