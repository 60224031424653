import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import LearningMainPage from "../learning/learningMainPage";

import UserInfo from "../computerFormat/boxes/userInfo";
import UserStatistic from "../computerFormat/boxes/userStatistics";

const bottomLinks = [
  {
    label: "tanulas",
    image: "/icons/bottomNav/home.png",
  },
  {
    label: "statisztika",
    image: "/icons/bottomNav/stats.png",
  },
  {
    label: "kuldetesek",
    image: "/icons/bottomNav/quest.png",
  },
  {
    label: "ranglista",
    image: "/icons/bottomNav/leaderboard.png",
  },
  {
    label: "profil",
    image: "/icons/bottomNav/profile.png",
  },
];

const getCurrentPageElement = (elementName: string) => {
  if (elementName === "tanulas") {
    return <LearningMainPage />;
  } else if (elementName === "profil") {
    return <UserInfo />;
  } else if (elementName === "statisztika") {
    return <UserStatistic />;
  } else {
    return <h1>Másik element</h1>;
  }
};
const getBottomLinks = (activeNav: string) => {
  const bottomLinkElements = bottomLinks.map((element, id) => {
    if (activeNav === element.label) {
      return (
        <BottomNavigationAction
          key={id}
          value={element.label}
          icon={
            <div
              style={{
                padding: 5,
                borderBottom: "3px solid var(--myNewGreen)",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={element.image}
                alt={element.label}
                style={{ width: 35, height: 35 }}
              />
            </div>
          }
          sx={{ color: "var(--myVeryLightBlue)!important", minWidth: "0px" }}
        />
      );
    } else {
      return (
        <BottomNavigationAction
          key={id}
          value={element.label}
          icon={
            <img
              src={element.image}
              alt={element.label}
              style={{ width: 35, height: 35 }}
            />
          }
          sx={{ color: "var(--myVeryLightBlue)!important", minWidth: "0px" }}
        />
      );
    }
  });
  return bottomLinkElements;
};

export default function PhoneFormat() {
  const [value, setValue] = React.useState("tanulas");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div>
      {getCurrentPageElement(value)}
      <BottomNavigation
        sx={{
          borderTop: "1px solid white",
          backgroundColor: "var(--myBg)",
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          minHeight: "70px",
        }}
        value={value}
        onChange={handleChange}
      >
        {getBottomLinks(value)}
      </BottomNavigation>
    </div>
  );
}
