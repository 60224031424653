import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Box, Button, Typography, Divider, Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import newAxios from "../../axios/axios";
import { AxiosResponse } from "axios";
import { UserRewardType } from "../../models/response/afterLogin/practice/userReward";

const handleEnterKey = (event: React.KeyboardEvent) => {
  const { key } = event;
  if (key === "Enter") {
    const finishButton = document.getElementById(
      "finishButton"
    ) as HTMLButtonElement;
    event.preventDefault();
    finishButton.click();
  }
};

export default function RewardPage(props: any) {
  const { finishedTasks, tasksStatus, target } = props;
  const { token } = useParams();
  const [isReady, setIsReady] = useState(false);
  const [userReward, setUserReward] = useState<UserRewardType>();
  const [openElements, setOpenElements] = useState<number | null>(null);
  const [maxRewardStep, setMaxRewardStep] = useState<number>(0);

  const getRewards = async () => {
    const getRoute = (): string => {
      if (target === "words_practice") {
        return "/app/practice/words/reward";
      } else if (target === "homework_practice") {
        return "/app/practice/homework/reward";
      } else {
        return "/app/practice/srs/reward";
      }
    };
    const response = (await newAxios(getRoute(), "POST", {
      tasks: finishedTasks,
      token,
      successRate: tasksStatus.successRate,
      totalGoodAnswer: tasksStatus.totalGoodAnswer,
      totalWrongAnswer: tasksStatus.totalWrongAnswer,
      smallMistakes: tasksStatus.smallMistakes,
      totalStudyTimeInSeconds: tasksStatus.totalStudyTimeInSeconds
    })) as AxiosResponse;
    const maxStep = userReward?.excellentWork === 0 ? 7 : 8;
    setMaxRewardStep(maxStep);
    if (response.status === 200) {
      setUserReward(response.data);
      setIsReady(true);
      setOpenElements(0);
    }
  };

  useEffect(() => {
    if (!isReady) {
      getRewards();
    }
    if (openElements !== null && openElements < maxRewardStep && isReady) {
      const interval = setInterval(() => {
        setOpenElements(openElements + 1);
      }, 250);
      return () => clearInterval(interval);
    }
    if (openElements === maxRewardStep) {
      const finishButton = document.getElementById(
        "finishButton"
      ) as HTMLButtonElement;
      finishButton.focus();
    }
    // eslint-disable-next-line
  }, [openElements]);

  if (isReady) {
    return (
      <Box textAlign={"center"} mt={2}>
        <Box sx={{ padding: 4 }}>
          {openElements! >= 0 && (
            <Box className="practice_words_play_rewardInfo" m={1}>
              <Typography variant="h5" fontWeight={600}>
                Feladat jutalom
              </Typography>
              <Typography
                color={"goldenrod"}
                textAlign={"center"}
                variant="h5"
                fontWeight={600}
              >
                +{userReward?.defaultXP} TP
              </Typography>
            </Box>
          )}
          {openElements! >= 1 && (
            <Box className="practice_words_play_rewardInfo" m={1}>
              <Typography variant="h5" fontWeight={600}>
                Előfizetői jutalom
              </Typography>
              <Typography color={"goldenrod"} variant="h5" fontWeight={600}>
                +{userReward?.subXP} TP
              </Typography>
            </Box>
          )}
          {openElements! >= 2 && (
            <Box className="practice_words_play_rewardInfo" m={1}>
              <Typography variant="h5" fontWeight={600}>
                Tudáspont szorzó
              </Typography>
              <Typography color={"goldenrod"} variant="h5" fontWeight={600}>
                {userReward?.exp_ratio}x
              </Typography>
            </Box>
          )}
          {openElements! >= 3 && (
            <Box className="practice_words_play_rewardInfo" m={1}>
              <Typography variant="h5" fontWeight={600}>
                Helyes/Hibás válaszok
              </Typography>
              <Typography color={"goldenrod"} variant="h5" fontWeight={600}>
                +{userReward?.totalGoodAnswer} TP
                {" / "}-{userReward?.totalWrongAnswer} TP
              </Typography>
            </Box>
          )}
          {openElements! >= 4 && (
            <Box className="practice_words_play_rewardInfo" m={1}>
              <Typography variant="h5" fontWeight={600}>
                Kis hibák
              </Typography>
              <Typography color={"goldenrod"} variant="h5" fontWeight={600}>
                -{userReward?.smallMistakes} TP
              </Typography>
            </Box>
          )}
          {openElements! >= 5 && userReward?.excellentWork !== 0 && (
            <Box className="practice_words_play_rewardInfo" m={1}>
              <Typography variant="h5" fontWeight={600}>
                Hiba nélkül!
              </Typography>
              <Typography color={"goldenrod"} variant="h5" fontWeight={600}>
                +{userReward?.excellentWork} TP
              </Typography>
            </Box>
          )}
          {openElements! >= (userReward?.excellentWork === 0 ? 5 : 6) && (
            <Box className="practice_words_play_rewardInfo" m={1}>
              <Typography variant="h5" fontWeight={600}>
                Eredmény
              </Typography>
              <Typography color={"goldenrod"} variant="h5" fontWeight={600}>
                {userReward?.successRate} %
              </Typography>
            </Box>
          )}
          {openElements! >= (userReward?.excellentWork === 0 ? 6 : 7) && (
            <Box mt={2} className="practice_words_play_rewardInfo">
              <Divider className="form-divider" />
              <Box mt={2}>
                <Typography variant="h5" fontWeight={600}>
                  Teljes jutalom
                </Typography>
                <Typography color={"goldenrod"} variant="h5" fontWeight={600}>
                  +{userReward?.rewardXP} Tudáspont
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        {openElements! >= maxRewardStep && (
          <Box className="practice_words_play_rewardInfo" textAlign={"right"}>
            <Button
              fullWidth
              id="finishButton"
              onKeyDown={handleEnterKey}
              className="form-button practice_words_play_rewardInfo_05"
              href="/app/"
            >
              Tovább
            </Button>
          </Box>
        )}
        <Box>
          <Typography></Typography>
        </Box>
      </Box>
    );
  } else {
    return (
      <Grid container spacing={2}>
        <Grid textAlign={"center"} mt={5} item xs={12}>
          <CircularProgress size={"5rem"} />
        </Grid>
      </Grid>
    );
  }
}
