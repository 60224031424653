import React, { useState } from "react";

import { Typography, Tooltip, Box } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";

export default function PracticeWordsTooltip(props: any) {
  const { tasks, tasksStatus } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const handleClickedTooltip = () => {
    setIsOpen(true);
    setIsClicked(true);
  };
  const handleClickedTooltipClose = () => {
    setIsOpen(false);
    setIsClicked(false);
  };
  const handleTooltipClose = () => {
    if (!isClicked) {
      setIsOpen(false);
    }
  };
  const handleTooltipOpen = () => {
    setIsOpen(true);
  };
  const getWords = (type: string | undefined) => {
    if (typeof type !== "undefined") {
      const checkLength = type.includes(",");
      if (checkLength) {
        const words = checkLength ? type.replaceAll(",", ", ") : type;
        return words;
      } else {
        return type;
      }
    }
  };

  const getTooltipValue = function (words: string | undefined) {
    return (
      <React.Fragment>
        {/* Ez a box a hun mező */}
        <Box>
          <Typography
            sx={{ textDecoration: "underline" }}
            fontWeight={600}
            p={1}
          >
            Gyakori fordítás/ok
          </Typography>
          <Typography sx={{ color: "gold" }} fontWeight={600} px={1}>
            {getWords(words)}
          </Typography>
        </Box>
        {/* Ez a box a főnevek mező */}
        {tasksStatus.type === 0 &&
          typeof tasks[tasksStatus.currentTask].hun_nouns !== "undefined" &&
          tasks[tasksStatus.currentTask].hun_nouns.length > 0 && (
            <Box>
              <Typography
                sx={{ textDecoration: "underline" }}
                fontWeight={600}
                p={1}
              >
                Főnevek
              </Typography>
              <Typography sx={{ color: "gold" }} fontWeight={600} px={1}>
                {getWords(tasks[tasksStatus.currentTask].hun_nouns)}
              </Typography>
            </Box>
          )}
        {/* Ez a box az igék mező */}
        {tasksStatus.type === 0 &&
          typeof tasks[tasksStatus.currentTask].hun_verbs === "string" &&
          tasks[tasksStatus.currentTask].hun_verbs.length > 0 && (
            <Box>
              <Typography
                sx={{ textDecoration: "underline" }}
                fontWeight={600}
                p={1}
              >
                Igék
              </Typography>
              <Typography sx={{ color: "gold" }} fontWeight={600} px={1}>
                {getWords(tasks[tasksStatus.currentTask].hun_verbs)}
              </Typography>
            </Box>
          )}
        {/* Ez a box a melléknevek mező */}
        {tasksStatus.type === 0 &&
          typeof tasks[tasksStatus.currentTask].hun_adjectives === "string" &&
          tasks[tasksStatus.currentTask].hun_adjectives.length > 0 && (
            <Box>
              <Typography
                sx={{ textDecoration: "underline" }}
                fontWeight={600}
                p={1}
              >
                Melléknevek
              </Typography>
              <Typography sx={{ color: "gold" }} fontWeight={600} px={1}>
                {getWords(tasks[tasksStatus.currentTask].hun_adjectives)}
              </Typography>
            </Box>
          )}
        {/* Ez a box a határozószók mező */}
        {tasksStatus.type === 0 &&
          typeof tasks[tasksStatus.currentTask].hun_adverbs === "string" &&
          tasks[tasksStatus.currentTask].hun_adverbs.length > 0 && (
            <Box>
              <Typography
                sx={{ textDecoration: "underline" }}
                fontWeight={600}
                p={1}
              >
                Határozószók
              </Typography>
              <Typography sx={{ color: "gold" }} fontWeight={600} px={1}>
                {getWords(tasks[tasksStatus.currentTask].hun_adverbs)}
              </Typography>
            </Box>
          )}
        {/* Ez a box a többes-szám mező */}
        {typeof tasks[tasksStatus.currentTask].hun_plural === "string" &&
          tasks[tasksStatus.currentTask].hun_plural.length > 0 && (
            <Box>
              <Typography
                sx={{ textDecoration: "underline" }}
                fontWeight={600}
                p={1}
              >
                Többes-szám
              </Typography>
              <Typography sx={{ color: "gold" }} fontWeight={600} px={1}>
                {getWords(tasks[tasksStatus.currentTask].eng_plural)} -{" "}
                {getWords(tasks[tasksStatus.currentTask].hun_plural)}
              </Typography>
            </Box>
          )}
        {/* Ez a box a rendhagyó alak mező */}
        {typeof tasks[tasksStatus.currentTask].hun_irregular === "string" &&
          tasks[tasksStatus.currentTask].hun_irregular.length > 0 && (
            <Box>
              <Typography
                sx={{ textDecoration: "underline" }}
                fontWeight={600}
                p={1}
              >
                Rendhagyó alak
              </Typography>
              <Typography sx={{ color: "gold" }} fontWeight={600} px={1}>
                {getWords(tasks[tasksStatus.currentTask].eng_irregular)} -{" "}
                {getWords(tasks[tasksStatus.currentTask].hun_irregular)}
              </Typography>
            </Box>
          )}
        {/* Ez a box a szinonimák mező */}
        {typeof tasks[tasksStatus.currentTask].eng_alternatives === "string" &&
          tasks[tasksStatus.currentTask].eng_alternatives.length > 0 && (
            <Box>
              <Typography
                sx={{ textDecoration: "underline" }}
                fontWeight={600}
                p={1}
              >
                Szinonimák
              </Typography>
              <Typography sx={{ color: "gold" }} fontWeight={600} px={1}>
                {getWords(tasks[tasksStatus.currentTask].eng_alternatives)}
              </Typography>
            </Box>
          )}
      </React.Fragment>
    );
  };
  return (
    <div>
      <ClickAwayListener onClickAway={handleClickedTooltipClose}>
        <Tooltip
          title={getTooltipValue(
            tasksStatus.type === 1
              ? tasks[tasksStatus.currentTask].eng
              : tasks[tasksStatus.currentTask].hun
          )}
          placement="bottom"
          arrow
          open={isOpen}
          onClick={handleClickedTooltip}
          onMouseLeave={handleTooltipClose}
          onMouseEnter={handleTooltipOpen}
          disableHoverListener
          PopperProps={{
            sx: {
              "& .MuiTooltip-arrow": {
                fontSize: 25,
                "&::before": {},
              },
            },
          }}
        >
          <Typography
            sx={{ cursor: "pointer", display: "inline" }}
            color="gold"
            fontWeight={600}
          >
            {getWords(
              tasksStatus.type === 1
                ? tasks[tasksStatus.currentTask].hun
                : tasks[tasksStatus.currentTask].eng
            )}
          </Typography>
        </Tooltip>
      </ClickAwayListener>
    </div>
  );
}
