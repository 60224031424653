import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Avatar,
  Typography,
  Container,
  Button,
  CircularProgress,
} from "@mui/material";
import newAxios from "../../../../../axios/axios";
import { indexWordsCategories } from "../../../../../models/response/afterLogin/indexWordsCategories";
import { PrepareWordsType } from "../../../../../models/response/afterLogin/prepareWords";
import WordsHeaderContainer from "../../../../../components/ui/index/wordsHeaderContainer";
import BorderLinearProgress from "../../../../../components/ui/progressBar";

export default function WordsPage() {
  const navigate = useNavigate();
  const [wordsCategories, setWordsCategories] = useState<indexWordsCategories>({
    data: [
      {
        mainTitle: "",
        mainIcon: "",
        SRSwords: 0,
        nextSRSLesson: 0,
      },
    ],
    isReady: false,
  });
  const [prepareWords, setPrepareWords] = useState({
    isActive: true,
    buttonID: "",
  });

  const [userWordsStatus, setUserWordsStatus] = useState({
    userDiscoveredWords: 0,
    userDiscoveredAdditionalWords: 0,
    totalCategoryWords: 0,
    totalAdditionalCategoryWords: 0,
    SRSwords: 0,
    nextSRSLesson: 0,
  });
  // default page 1
  const [currentPagiPage, setCurrentPagiPage] = useState(1);
  // default item 1,1 left value is parent, right child id
  const [currentPagiItem, setCurrentPagiItem] = useState("1,1");
  const itemPerPage = 2;

  const getWordsCategories = async () => {
    const response = (await newAxios(
      "/app/words-categories",
      "GET"
    )) as indexWordsCategories;
    if (response.status === 200) {
      response.data!.forEach((item) => {
        setUserWordsStatus((prevState) => ({
          ...prevState,
          totalCategoryWords: (prevState.totalCategoryWords +=
            item.totalCategoryWords!),
          totalAdditionalCategoryWords:
            (prevState.totalAdditionalCategoryWords +=
              item.totalAdditionalCategoryWords!),
          userDiscoveredWords: (prevState.userDiscoveredWords +=
            item.userDiscoveredWords!),
          userDiscoveredAdditionalWords:
            (prevState.userDiscoveredAdditionalWords +=
              item.userDiscoveredAdditionalWords!),
        }));
      });
      // update SRSwords value
      setUserWordsStatus((prevState) => ({
        ...prevState,
        SRSwords: response.data![0].SRSwords,
        nextSRSLesson: response.data![0].nextSRSLesson,
      }));
      setWordsCategories({
        data: response.data,
        isReady: true,
      });
      // update words page pagination page and item value from session (default page 1 and item value 1,1)
      setCurrentPagiPage(
        response.data?.[0].userLastSelectedLesson?.lastPage as number
      );
      setCurrentPagiItem(
        response.data?.[0].userLastSelectedLesson?.lastItem as string
      );
    }
  };

  const prepareTask = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const targetCategory = e.currentTarget.value;
    setPrepareWords({ isActive: true, buttonID: targetCategory });
    // send a post request with selected category and current category pagination page number
    const response = (await newAxios("/app/prepare/words", "POST", {
      targetCategory,
      currentPagiPage,
    })) as PrepareWordsType;
    if (response.status === 200 && response.data!.success) {
      const token = response.data?.token;
      setPrepareWords((prevState) => ({
        ...prevState,
        isActive: false,
      }));
      navigate("/app/practice/words/" + token);
    } else {
      setPrepareWords((prevState) => ({
        ...prevState,
        isActive: false,
      }));
    }
  };

  // after wordsCategories ready we scroll to last selected item
  const scrollIntoViewTarget = () => {
    const lastFocusedElement = document.querySelector(
      `[value="${currentPagiItem}"]`
    );
    if (lastFocusedElement) {
      lastFocusedElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  // get wordsCategories
  useEffect(() => {
    getWordsCategories();
  }, []);

  // scroll to last selected item after WC is ready
  useEffect(() => {
    if (wordsCategories.isReady) {
      scrollIntoViewTarget();
    }
    //eslint-disable-next-line
  }, [wordsCategories.isReady]);

  const getProgressPercent = (
    userDiscoveredWords: number,
    wordsCategoryTotalWords: number
  ) => {
    const catTotalWords =
      wordsCategoryTotalWords === null ? 0 : wordsCategoryTotalWords;
    const userCatWords = userDiscoveredWords === null ? 0 : userDiscoveredWords;
    if (userCatWords === 0 && catTotalWords === 0) {
      return 0;
    } else {
      const currentPercent = (userCatWords / catTotalWords) * 100;
      return currentPercent;
    }
  };

  if (wordsCategories.isReady) {
    return (
      <Box p={2}>
        <WordsHeaderContainer
          wordsCategories={wordsCategories}
          userWordsStatus={userWordsStatus}
          setCurrentPagiPage={setCurrentPagiPage}
          currentPagiPage={currentPagiPage}
          itemPerPage={itemPerPage}
        />
        {wordsCategories.data
          ?.slice(
            currentPagiPage * itemPerPage - itemPerPage,
            currentPagiPage * itemPerPage
          )
          .map((category, i) => {
            return (
              <Box key={i}>
                <Grid
                  sx={{
                    backgroundColor: "#181b1f3a!important",
                    borderRadius: "10px",
                    padding: "0.3rem",
                  }}
                  mt={3}
                  container
                >
                  <Grid item xs={3} sm={2} md={1}>
                    <img src={category.mainIcon} alt={category.mainTitle}></img>
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    sm={10}
                    md={3}
                    m={"auto"}
                    textAlign={"left"}
                  >
                    <Typography variant={"h5"} display={"inline"} mx={1}>
                      {category.mainTitle}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8} m={"auto"} textAlign={"right"}>
                    <Typography variant={"h6"} display={"inline"} mx={1}>
                      Kezdő{" "}
                      {typeof category.userDiscoveredWords !== "number"
                        ? 0
                        : category.userDiscoveredWords}{" "}
                      / {category.totalCategoryWords}
                    </Typography>
                    <Typography variant={"h6"} display={"inline"} mx={1}>
                      Haladó{" "}
                      {typeof category.userDiscoveredAdditionalWords !==
                      "number"
                        ? 0
                        : category.userDiscoveredAdditionalWords}{" "}
                      / {category.totalAdditionalCategoryWords}
                    </Typography>
                  </Grid>
                </Grid>
                {/* itt lesznek a al kategóriák */}
                <Grid
                  my={2}
                  container
                  justifyContent={"flex-start"}
                  textAlign="center"
                >
                  {category.children?.map((child, i) => {
                    return (
                      <Grid
                        sx={{ marginY: "7px" }}
                        key={i}
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        xl={4}
                        textAlign={"center"}
                      >
                        <Box sx={{ maxWidth: "99%" }}>
                          <Container
                            className={
                              child.isAdditional === 0
                                ? "index_rightBox_childrenWordsContainer_beginner"
                                : "index_rightBox_childrenWordsContainer_advanced"
                            }
                          >
                            <Typography
                              sx={{ textDecoration: "underline" }}
                              className={
                                child.isAdditional === 0
                                  ? "index_rightbox_wordsTitle_beginner"
                                  : "index_rightbox_wordsTitle_advanced"
                              }
                              fontWeight={600}
                              fontSize={15}
                            >
                              {child.childTitle}
                            </Typography>
                            <Avatar
                              src={child.childIcon}
                              className="words_icons"
                              variant="square"
                            ></Avatar>
                            <Typography
                              className={
                                child.isAdditional === 0
                                  ? "index_rightbox_wordsTitle_beginner"
                                  : "index_rightbox_wordsTitle_advanced"
                              }
                              fontWeight={600}
                            >
                              {child.isAdditional === 0 ? "Kezdő" : "Haladó"}
                            </Typography>
                            <Grid container>
                              <Grid m={"auto"} pt={2} item xs={12}>
                                <BorderLinearProgress
                                  variant="determinate"
                                  value={getProgressPercent(
                                    child.userDiscoveredWords!,
                                    child.totalWords!
                                  )}
                                />
                              </Grid>
                              <Grid
                                textAlign={"left"}
                                mt={1}
                                pl={1}
                                xs={6}
                                item
                              >
                                {child.userDiscoveredWords === null
                                  ? 0
                                  : child.userDiscoveredWords}
                              </Grid>
                              <Grid
                                textAlign={"right"}
                                mt={1}
                                pr={1}
                                xs={6}
                                item
                              >
                                {child.totalWords === null
                                  ? 0
                                  : child.totalWords}
                              </Grid>
                            </Grid>
                            <Button
                              variant="contained"
                              size="small"
                              value={`${category.mainID + "," + child.childID}`}
                              onClick={prepareTask}
                              className={
                                child.isAdditional === 0
                                  ? "index_rightBox_childrenWordsButton_beginner"
                                  : "index_rightBox_childrenWordsButton_advanced"
                              }
                            >
                              {prepareWords.isActive &&
                              prepareWords.buttonID ===
                                category.mainID + "," + child.childID ? (
                                <CircularProgress size={"1.5rem"} />
                              ) : (
                                "Gyakorlás"
                              )}
                            </Button>
                          </Container>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
                {/* all kat vége */}
              </Box>
            );
          })}
      </Box>
    );
  } else {
    return (
      <Grid container spacing={2}>
        <Grid textAlign={"center"} mt={5} item xs={12}>
          <CircularProgress size={"5rem"} />
        </Grid>
      </Grid>
    );
  }
}
