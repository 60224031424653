import React from "react";
import { useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Navigation from "./navigation";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import Button from "@mui/material/Button";

import HU from "../../../../languages/HU/hu";
import { SelectChangeEvent } from "@mui/material";

export default function UserSubscription() {
  const [selectValue, setSelectValue] = useState("1m");
  function updateSelectValue(e: SelectChangeEvent<string>) {
    setSelectValue(e.target.value);
  }
  return (
    <Box className="settings-content">
      <Navigation />
      <Container
        className="settings-content-container"
        component="main"
        maxWidth="sm"
      >
        <Typography mb={3} variant="h5" sx={{ fontWeight: "bold" }}>
          {HU.user_settings.subscription.noActivetitle}
        </Typography>
        {/* <Typography m={2} variant="h6" align="left">
          {HU.user_settings.subscription.whyWorth}
        </Typography>
        <Container>
          <ul>
            <li>Összes tananyag/feladat elérése</li>
            <li>
              Naponta kapsz új szavakat emailben amiket gyakorolni tudsz
              választható mennyiségben (5, 10, 25)
            </li>
            <li>Hibáidra külön gyakorló feladatok</li>
            <li>Reklámok nélkül élvetheted tovább!</li>
            <li>Üzeneteid priorítást élveznek csapatunk felé</li>
            <li>
              Amíg az előfitésed aktív a szériád nem tud megszakadni bármennyi
              napot is hagysz ki!
            </li>
            <li>Bla bla stb majd kiderül</li>
          </ul>
          <p>Az előfizetésed közben bármikor lemondhatod!</p>
          <p>
            Az előfizetés megújítása/lejárta elött 1 héttel és elötte 1 nappal
            is küldünk majd emailt!
          </p>
        </Container>
        <Typography m={2} variant="h6">
          {HU.user_settings.subscription.noActiveSubtitle}
        </Typography>
        <FormControl className="support_messages_selector">
          <Select
            sx={{ padding: 1, marginBottom: 2 }}
            value={selectValue}
            onChange={updateSelectValue}
            autoWidth
          >
            <MenuItem value={"1m"}>Havonta megújuló csomag (2999 Ft)</MenuItem>
            <MenuItem value={"3m"}>
              3 Havonta megújuló csomag (7999 Ft)
            </MenuItem>
            <MenuItem value={"6m"}>
              6 Havonta megújuló csomag (13999 Ft)
            </MenuItem>
            <MenuItem value={"12m"}>
              12 Havonta megújuló csomag (24999 Ft)
            </MenuItem>
          </Select>
          <Button
            className="form-button"
            type="submit"
            size="large"
            variant="contained"
            color="inherit"
          >
            {HU.user_settings.subscription.nextButton}
          </Button>
        </FormControl> */}
      </Container>
    </Box>
  );
}
