import { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import newAxios from "../../axios/axios";
import { UpdatedAvatar } from "../../models/response/updatedAvatar";
// if you add a new Avatar in the public folder, update Avatars file with this data
import Avatars from "../avatars/avatarList";

export default function ProfilImage(props: {
  userAvatar: string;
  setUserAvatar: React.Dispatch<React.SetStateAction<string>>;
}) {
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const [selectedImage, setSelectedImage] = useState("");
  const [value, setValue] = useState("0");

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedImage("");
  };
  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    setValue(newValue);
  };

  const getSelectedImaged = (e: any) => {
    setSelectedImage(e.target.getAttribute("src"));
  };

  const saveSelectedImage = async () => {
    const response = (await newAxios(
      "/app/settings/profile/new/avatar",
      "POST",
      {
        newAvatar: selectedImage,
      }
    )) as UpdatedAvatar;
    if (response.status === 200 && response.data.success) {
      handleCloseModal();
      props.setUserAvatar(selectedImage as string);
    }
  };

  return (
    <div>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box className="profile_image_modal_box">
          <TabContext value={value}>
            <Box>
              <Typography
                m={1}
                align="center"
                variant="h6"
                sx={{ fontWeight: "bold" }}
              >
                Profilkép módosítása
              </Typography>
              <TabList
                onChange={handleChange}
                className="profile_image_modal_box_tablist"
                TabIndicatorProps={{
                  className: "settings-navigation-tab-indicator",
                }}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                sx={{ width: "100%", margin: "auto" }}
              >
                {Avatars.map((item, i) => {
                  return (
                    <Tab label={item.folderName} key={i} value={i.toString()} />
                  );
                })}
              </TabList>
            </Box>
            {Avatars.map((item, i) => {
              return (
                <TabPanel key={i} value={i.toString()}>
                  <Grid container>
                    {item.images.map((item, imgI) => {
                      return (
                        <Grid className="avatars_grid_item" key={imgI}>
                          <input
                            type="radio"
                            name="emotion"
                            id={imgI.toString()}
                            className="input-hidden"
                          />
                          <label htmlFor={imgI.toString()}>
                            <img
                              src={item}
                              alt={imgI.toString()}
                              loading="lazy"
                              onClick={getSelectedImaged}
                            />
                          </label>
                        </Grid>
                      );
                    })}
                  </Grid>
                </TabPanel>
              );
            })}
            <Grid container>
              <Grid item xs={6}>
                <Button
                  className="danger-button"
                  size="large"
                  fullWidth
                  variant="contained"
                  onClick={handleCloseModal}
                >
                  Mégsem
                </Button>
              </Grid>
              <Grid item xs={6}>
                {selectedImage === "" ? (
                  <Button
                    className="success-button"
                    size="large"
                    fullWidth
                    variant="contained"
                    disabled
                  >
                    Mentés
                  </Button>
                ) : (
                  <Button
                    className="success-button"
                    size="large"
                    fullWidth
                    variant="contained"
                    onClick={saveSelectedImage}
                  >
                    Mentés
                  </Button>
                )}
              </Grid>
            </Grid>
          </TabContext>
        </Box>
      </Modal>
      <Avatar
        className="profile_image_avatar"
        onClick={handleOpenModal}
        alt="profile-img"
        src={props.userAvatar}
        title="Profilkép módosítása"
      />
    </div>
  );
}
