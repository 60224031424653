import { useEffect } from "react";
import newAxios from "../../axios/axios";
import { SessionResponse } from "../../models/response/session";
import { UserState } from "../../models/userState";

export default function Logout(props: {setUser: React.Dispatch<React.SetStateAction<UserState>>}) {
  async function userLogout() {
    const response = await newAxios("/app/logout", "get") as SessionResponse;
    if (response.status === 200) {
      props.setUser((prevState: UserState) => ({
        ...prevState,
        isLogged: false,
      }));
    }
  }
  useEffect(() => {
    userLogout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
}
