export default function removeDuplicatesAndSpaces(input: string) {
  // Távolítsa el a szóközöket a sztringből
  const cleanedInput = input.replace(/,\s+/g, ",");
  const cleanedDouble = cleanedInput.replace(",,", ",");

  // Ossza fel a szöveget vesszők mentén
  const words = cleanedDouble.split(",");

  // Használjon Set-et az ismétlődő szavak eltávolítására
  const uniqueWords = [...new Set(words)];

  // Alakítsa vissza a tömböt sztringgé, vesszőkkel elválasztva
  const result = uniqueWords.join(",");
  // Távolítsa el az utolsó vesszőt, ha az ott van
  /* const finalResult = result.replace(/,$/, ''); */

  return result;
}
