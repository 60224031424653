import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import FaceIcon from "@mui/icons-material/Face";
import HomeIcon from "@mui/icons-material/Home";
import KeyIcon from "@mui/icons-material/Key";
import EmailIcon from "@mui/icons-material/Email";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import FavoriteIcon from "@mui/icons-material/Favorite";

const Links = {
  siteName: "Beta",
  header: [
    { linkName: "a", href: "#" },
    { linkName: "b", href: "#" },
    { linkName: "c", href: "#" },
  ],
  protectedHeader: [
    { linkName: "Előfizetés", href: "#", icon: "/icons/userbox/01.png" },
    { linkName: "GYIK", href: "#", icon: "/icons/userbox/02.png" },
  ],
  protectedIndexPageLinks: [
    { name: "Ösvény", img: "/icons/rightbox/01.png" },
    { name: "Szavak", img: "/icons/rightbox/02.png" },
    { name: "Előzmények", img: "/icons/rightbox/03.png" },
    { name: "Leckék", img: "/icons/rightbox/04.png" },
    { name: "Statisztika", img: "/icons/bottomNav/stats.png", phoneSize: "hidden" },
    { name: "Ranglista", img: "/icons/bottomNav/leaderboard.png", phoneSize: "hidden" },
  ],
  protectedHeaderUserLinks: [
    {
      linkName: "Főoldal",
      href: "/app",
      icon: <HomeIcon className="dropdown-icons" />,
      afterDivider: false,
    },
    {
      linkName: "Profil",
      href: "/app/settings/profile",
      icon: <FaceIcon className="dropdown-icons" />,
      afterDivider: false,
    },
    {
      linkName: "Előfizetés",
      href: "/app/settings/subscription",
      icon: <FavoriteIcon className="dropdown-icons" />,
      afterDivider: false,
    },
    {
      linkName: "Ismerős meghívása",
      href: "/app/settings/invite",
      icon: <PersonAddIcon className="dropdown-icons" />,
      afterDivider: false,
    },
    {
      linkName: "Üzenetek | Üzenet küldése",
      href: "/app/settings/support",
      icon: <EmailIcon className="dropdown-icons" />,
      afterDivider: true,
    },
    {
      linkName: "Beállítások",
      href: "/app/settings/account",
      icon: <Settings className="dropdown-icons" />,
      afterDivider: false,
    },
    {
      linkName: "Jelszó módosítása",
      href: "/app/settings/password",
      icon: <KeyIcon className="dropdown-icons" />,
      afterDivider: false,
    },
    {
      linkName: "Kijelentkezés",
      href: "/app/logout",
      icon: <Logout className="dropdown-icons" />,
      afterDivider: false,
    },
  ],

  indexPageLeftBoxIcons: [
    {
      linkName: "Profil",
      href: "/app/settings/profile",
      icon: <FaceIcon className="index_leftBox_muiIcons" />,
    },
    {
      linkName: "Előfizetés",
      href: "/app/settings/subscription",
      icon: <FavoriteIcon className="index_leftBox_muiIcons" />,
    },
    {
      linkName: "Ismerős meghívása",
      href: "/app/settings/invite",
      icon: <PersonAddIcon className="index_leftBox_muiIcons" />,
    },
    {
      linkName: "Üzenetek | Üzenet küldése",
      href: "/app/settings/support",
      icon: <EmailIcon className="index_leftBox_muiIcons" />,
    },
    {
      linkName: "Beállítások",
      href: "/app/settings/account",
      icon: <Settings className="index_leftBox_muiIcons" />,
    },
    {
      linkName: "Jelszó módosítása",
      href: "/app/settings/password",
      icon: <KeyIcon className="index_leftBox_muiIcons" />,
    },
    {
      linkName: "Kijelentkezés",
      href: "/app/logout",
      icon: <Logout className="index_leftBox_muiIcons" />,
    },
  ],

  userSettingsNavigation: [
    { name: "Profil", url: "/app/settings/profile" },
    { name: "Előfizetés", url: "/app/settings/subscription" },
    { name: "Jelszó", url: "/app/settings/password" },
    { name: "Meghívó", url: "/app/settings/invite" },
    { name: "Beállítások", url: "/app/settings/account" },
    { name: "Segítség", url: "/app/settings/support" },
  ],
  supportSettingsNavigation: [
    { name: "Üzenetek", url: "/app/support/messages" },
    { name: "Üzenet küldése", url: "/app/support/message/send" },
    { name: "Jutalmak kezelése", url: "/app/support/rewards" },
  ],
};

export default Links;
