import { Route, Routes, Navigate } from "react-router-dom";
import { useState } from "react";

import Header from "../components/header/header";
import ProtectedHeader from "../components/header/protectedHeader";
import Footer from "../components/footer/footer";

// pages
import Home from "./pages/home";
import Register from "./pages/forms/register";
import Login from "./pages/forms/login";
import Forgot from "./pages/forms/forgot";
import NewPassword from "./pages/forms/newPassword";
// user Activation
import UserActivation from "./userActivation/userActivation";
// user Invitation
import Invitation from "./pages/forms/invitation";
// protected user pages
import Index from "./pages/protected/index";
import UserProfile from "./pages/protected/settings/userProfile";
import UserSubscription from "./pages/protected/settings/subscription";
import UserPassword from "./pages/protected/settings/userPassword";
import UserInvite from "./pages/protected/settings/userInvite";
import UserSettings from "./pages/protected/settings/userSettings";
import UserSupport from "./pages/protected/settings/userSupport";
import UserTicket from "./pages/protected/tickets/userTicket";
import Logout from "./logout/logout";
// protected support/admin pages
import Messages from "./pages/protected/support/messages";
import Rewards from "./pages/protected/support/rewards";
import SendMessages from "./pages/protected/support/sendMessages";
// protected index pages
import WordsPracticeIndex from "./pages/protected/practice/words/wordsPracticeIndex";
import WordsHomeworkIndex from "./pages/protected/practice/homework/wordsHomework";
import SrsWordsIndex from "./pages/protected/practice/srs/srsWords";

import { UserState } from "../models/userState";

export default function Routing(props: { user: UserState, setUser: React.Dispatch<React.SetStateAction<UserState>>}) {
  const [userAvatar, setUserAvatar] = useState(props.user.avatar);
  // Protected routes (if user logged)
  if (props.user.isLogged) {
    return (
      <div>
        <ProtectedHeader userAvatar={userAvatar}/>
        <Routes>
          <Route path="/app/" element={<Index />} />
          <Route path="/app/practice/srs/" element={<SrsWordsIndex />} />
          <Route path="/app/practice/words/:token" element={<WordsPracticeIndex />} />
          <Route path="/app/practice/homework/:token" element={<WordsHomeworkIndex />} />
          <Route path="/app/settings/profile" element={<UserProfile userAvatar={userAvatar} setUserAvatar={setUserAvatar}/>}/>
          <Route path="/app/settings/subscription" element={<UserSubscription />} />
          <Route path="/app/settings/password" element={<UserPassword />} />
          <Route path="/app/settings/invite" element={<UserInvite />} />
          <Route path="/app/settings/account" element={<UserSettings />} />
          <Route path="/app/settings/support" element={<UserSupport />} />
          <Route path="/app/settings/support/tickets/:ticket_token" element={<UserTicket />} />
          <Route path="/app/support/messages" element={<Messages />} />
          <Route path="/app/support/message/send" element={<SendMessages />} />
          <Route path="/app/support/rewards" element={<Rewards />} /> 
          <Route path="/app/logout" element={<Logout setUser={props.setUser}/>} />
          <Route path="/*" element={<Navigate to="/app/" />} />
        </Routes>
      </div>
    );
  } else {
    // Not protected routes
    return (
      <div>
        <Header />
          <Routes>
            {/* <Route path="/" element={<Home />} />
            <Route path="/register" element={<Register />} /> */}
            <Route path="/login" element={<Login setUser={props.setUser} setUserAvatar={setUserAvatar}/>} />
            <Route path="/forgot" element={<Forgot />} />
            <Route path="/forgot/:tokenid" element={<NewPassword />} />
            <Route path="/activation/:ref_id" element={<UserActivation />} />
            <Route path="/invitation/:ref_id" element={<Invitation />} />
            <Route path="/*" element={<Navigate to="/login" />} />
          </Routes>
        <Footer />
      </div>
    );
  }
}
