import React, { useState } from "react";
import Container from "@mui/material/Container";
import { Avatar, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
//pages
import WordsPage from "../studyElements/wordsPage";
import HistoryPage from "../studyElements/historyPage";
import HomeworkPage from "../studyElements/homeworkPage";
import UserStatistic from "../computerFormat/boxes/userStatistics";

import Links from "../../../../../languages/HU/links";
import { Hidden } from "@mui/material";

const pages = Links.protectedIndexPageLinks;

export default function LearningMainPage() {
  const [activeButton, setActiveButton] = useState<string>("Szavak");
  const setActiveLink = (e: React.MouseEvent<HTMLButtonElement>) => {
    setActiveButton(e.currentTarget.value);
  };
  const getCurrentPage = () => {
    if (activeButton === "Szavak") {
      return <WordsPage />;
    } else if (activeButton === "Előzmények") {
      return <HistoryPage />;
    } else if (activeButton === "Leckék") {
      return <HomeworkPage />;
    } else if (activeButton === "Statisztika") {
      return <UserStatistic />;
    } else {
      return <h1 style={{marginLeft: 5}}>Fejlesztés alatt...</h1>;
    }
  };
  const getButtons = () => {
    const buttons = pages.map((page, index) => {
      if (page.phoneSize === "hidden") {
        return (
          <Hidden key={index} only={["xs", "sm"]}>
            <Grid item xs={6} sm={6} md={4} xl={2} >
              <Button
                value={page.name}
                onClick={setActiveLink}
                className={
                  activeButton === page.name ? "activeIndexLinks" : "indexLinks"
                }
              >
                <Avatar
                  src={page.img}
                  className="index_words_icons"
                  variant="square"
                  sx={{ width: "35px!important", height: "35px!important" }}
                ></Avatar>
                <Typography sx={{ marginLeft: 1 }}>{page.name}</Typography>
              </Button>
            </Grid>
          </Hidden>
        );
      } else {
        return (
          <Grid item xs={6} sm={6} md={4} xl={2} key={index}>
            <Button
              value={page.name}
              onClick={setActiveLink}
              className={
                activeButton === page.name ? "activeIndexLinks" : "indexLinks"
              }
            >
              <Avatar
                src={page.img}
                className="index_words_icons"
                variant="square"
                sx={{ width: "35px!important", height: "35px!important" }}
              ></Avatar>
              <Typography sx={{ marginLeft: 1 }}>{page.name}</Typography>
            </Button>
          </Grid>
        );
      }
    });
    return buttons;
  };
  return (
    <Box className="indexRightBoxContent">
      <Container
        className="settings-navigation-header"
        sx={{ textAlign: "center" }}
      >
        <Grid container>{getButtons()}</Grid>
      </Container>
      {getCurrentPage()}
    </Box>
  );
}
