import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  padding: 4,
  border: "1px solid black",
  borderRadius: 15,
  boxShadow:
    "inset 0 10px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08)",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "grey",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 15,
    backgroundColor: "var(--progressBarColor)",
    boxShadow:
      "inset 0 8px 2px rgba(0, 0, 0, 0.25), 0px 1px rgba(255, 255, 255, 0.08)",
  },
}));

export default BorderLinearProgress;
